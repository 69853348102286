import React from 'react';
import ReleaseItem from './Item';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import capitalize from 'capitalize';
import {detectCategoryUrl} from '../Helper'

export const KicksonfireBlock = (props) => {
    if (props.items.length > 0) {
        const mobilesize = props.items.length<=4 ? 12/props.items.length : 6;
        const releases = props.items.map((release, index) => {
            return (
                <ReleaseItem key={release.id} mobilesize={mobilesize} item={release} country={props.country} {...props}  />
            );
        });
        const title = props.hasOwnProperty('title') ? capitalize.words(props.title) : "RELATED";
        let showAll = '';
        if(props.showAll && props.showAllLink){
            showAll = <div className='show-all-wrapper'>
                <Link to={props.showAllLink} aria-label={"Show All '" + title + "' Releases"}><span className="btn btn-snkrly btn-snkly-small hidden-xs">See All</span><i className="fas fa-arrow-right visible-xs"></i></Link>
            </div>
        }
        const id = props.hasOwnProperty('id') ? props.id + '-container':'kof-block-container';
        const className = 'kof-block-wrapper' + (props.hasOwnProperty('className') ? ' '+ props.className : '');
        return (
            <div id={id} className={className}>
                <div className='kof-block-headline'><h2>{title}</h2>{showAll}</div>
                <Row className='kof-block-content releases-container'>{releases}</Row>
            </div>
        );
    } else {
        return <></>;
    }
}

export const KicksonfireRelatedBlock = (props) => {
    if (props.items.length > 0) {
        const mobilesize = 6;//props.items.length<=4 ? 12/props.items.length : 6;
        const releases = props.items.map((release, index) => {
            return (
                <ReleaseItem showPrice={true} key={release.id} mobilesize={mobilesize} item={release} country={props.country} />
            );
        });
        const title = props.hasOwnProperty('title') ? props.title : "RELATED";
        const description = props.tag?.description?.length > 0 ? props.tag.description : '';
        const url = props.showAllLink;
        let showAllLink = '';
        let showMoreLink = '';
        if(props.showAll && props.showAllLink && props.items.length >= 4){
            showAllLink = (
                <div className='show-all-wrapper'>
                    <Link to={props.showAllLink}  aria-label={"Show All '" + title + "' Releases"}><span className="btn btn-snkrly btn-snkly-small hidden-xs">See All</span><i className="fas fa-arrow-right visible-xs"></i></Link>
                </div>
            );
            showMoreLink = (
                <div id="pagination-wrapper" className="col-lg-12">
                    <ul className="pager pager-lg">
                        <li><Link to={props.showAllLink} alt={"More '" +title + "' Releases"}>More Releases</Link></li>
                    </ul>
                </div>
            );
        }
        const id = props.hasOwnProperty('id') ? props.id + '-container':'related-container';
        const className = 'kof-block-wrapper' + (props.hasOwnProperty('className') ? ' '+ props.className : '');
        
        return (
            <div id={id} className={className}>
                <div className='kof-block-headline'><h2>{title}</h2>{showAllLink}</div>
                {description.length > 0 ? <BrandTagDescription title={title} url={url} description={description} /> : ''}
                <div className='kof-block-content releases-container'>
                    {releases}
                    <div className="clearfix"></div>
                </div>
                {showMoreLink}
            </div >
        );
    } else {
        return <div />;
    }
}


export const KicksonfireTagBrandBlock = (props) => {
    if (props.brand && props.brand.description.length > 0) {
        const title = props.brand.name;
        const url = detectCategoryUrl(props.brand.slug);
        const description = props.brand.description;
        const id = props.brand.hasOwnProperty('id') ? props.brand.id + '-container':'related-container';
        const className = 'kof-block-wrapper' + (props.hasOwnProperty('className') ? ' '+ props.className : '');
        
        return (
            <div id={id} className={className}>
                <div className='kof-block-headline'><h2><Link to={url} alt={title}>{title}</Link></h2></div>
                <div className='kof-block-content'>
                    {description.length > 0 ? <BrandTagDescription title={title} url={url} description={description} /> : ''}
                </div>
            </div >
        );
    } else {
        return <div />;
    }
}

export const BrandTagDescription = (props) => {
    if(props.description.length > 0){
        const showMoreLink = (<Link to={props.url} alt={props.title}>{props.title}</Link>);
        const desc_length = props.description.length > 300 ? 300 : props.description.length;
        let short_description = props.description.substr(0, desc_length);
        const last_space_position = short_description.lastIndexOf(' ');
        short_description = short_description.substr(0, last_space_position) + '&nbsp...&nbsp;&nbsp;&nbsp;';
        return (
            <div className='description'><span  dangerouslySetInnerHTML={{__html: short_description}}/>{'See More:  '}<b>{showMoreLink}</b></div>
        )
    }
    return '';
}