'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { Row, Col, Grid, Image, Clearfix } from 'react-bootstrap';
import { setMetatags, topInfluencers } from '../actions/settings';
import { page_metas } from './Helper';

class Home extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.home;
    this.props.setMetatags(metatags);
    this.state = {
      intro_bg: '',//'/assets/template/images/fullbackgrounds/intro_' + introbgnum + '.jpg',
      section_two_bg: '',//'/assets/template/images/fullbackgrounds/intro_' + twobgnum + '.jpg'
    }
  }

  componentDidMount() {
    this.props.topInfluencers();
    const introbgnum = Math.floor(Math.random() * 20) + 1;
    const twobgnum = Math.floor(Math.random() * 20) + 1;
    this.setState({
      intro_bg: '/assets/template/images/fullbackgrounds/intro_' + introbgnum + '.jpg',
      section_two_bg: '/assets/template/images/fullbackgrounds/intro_' + twobgnum + '.jpg'
    });
  }

  componentWillUnmount() {
    this.props.clearAlerts();
  }

  render() {
    const intro_styles = this.state.intro_bg != '' ? {
      backgroundImage: 'url("images/overlay.png"), url(' + this.state.intro_bg + ')',
    } : {};
    const section_two_styles = this.state.section_two_bg != '' ? {
      backgroundImage: 'url("images/overlay.png"), url(' + this.state.section_two_bg + ')',
    } : {};
    const partner_header_text = "Top Partners & Influencers";
    const top_infs = this.props.top_influencers.length > 0 ?
      this.props.top_influencers.map((item, index) => {
        if (item.id) {
          const bg_style = {
            backgroundImage: 'url(' + item.logo + ')',
          };
          return (
            <Col key={index} xs={12} sm={4} md={4} className="snkrly-icons">
              <div className="snkrly-icon" style={bg_style}>
                {/* <Link to={item.domain} target="blank" className="snkrly-icon-link" /> */}
              </div>
            </Col>)
        }
        return '';
      }) : '';
    return (
      <Grid fluid>
        {/* // < !--Header -- > */}

        {/* // <!--Intro --> */}
        <section id="intro" className="main style1 dark fullscreen" style={intro_styles}>
          <div className="content box">
            <header>
              <h2>
                <div className="nowrap">Transform your <br className="visible-xs" /><span data-period="2000" className="typewrite" data-type='["site", "blog", "network", "app", "influence"]' id="change-word">influence</span></div>
                <div className="nowrap">into a thriving  <br className="visible-xs" />e-commerce</div>
                <div className="nowrap">business.</div>
              </h2>
            </header>
            <footer>
              <Link to='/invite' className="btn btn-default btn-invite ">Request Invite</Link>
            </footer>
          </div>
        </section>

        {/* // <!--One --> */}
        <section id="one" className="main style1 primary dark fullscreen">
          <div className="content box style2">
            <Row>
              <Col xs={12} sm={4} md={4} className="snkrly-icons">
                <div className="snkrly-icon"><Image src="/default/image-kixify.png" responsive /></div>
                <div className="snkrly-icon-info">$100M+Unlimited<br />Inventory Access</div>
              </Col>
              <Col xs={12} sm={4} md={4} className="snkrly-icons">
                <div className="snkrly-icon"><Image src="/default/shield-outline.png" responsive /></div>
                <div className="snkrly-icon-info">100% Authenticity<br />Guarantee</div>
              </Col>
              <Col xs={12} sm={4} md={4} className="snkrly-icons">
                <div className="snkrly-icon"><Image src="/default/image-world.png" responsive /></div>
                <div className="snkrly-icon-info">1000+ Fullfillment<br />Centers Worldwide</div>
              </Col>
            </Row>
          </div>
        </section>

        {/* // <!--Two --> */}
        <section id="two" className="main style1 primary dark fullscreen" style={section_two_styles}>
          <div className="content box style2">
            <header>
              <h2>{partner_header_text}</h2>
            </header>
            <Row>
              {top_infs}
              <Clearfix />
            </Row>
          </div>
          <Clearfix />
        </section>
      </Grid >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    top_influencers: state.settings.top_influencers ? state.settings.top_influencers : []
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    topInfluencers: () => {
      dispatch(topInfluencers());
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
