'use strict';
import React from 'react';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { FormattedNumber } from 'react-intl';
import moment from 'moment';
import {
    FormControl, FormGroup, ControlLabel,
    HelpBlock, ButtonToolbar, Button, Clearfix,
    Row, Col, PageHeader, Grid
} from 'react-bootstrap';
import NavLink from './NavLink';

import { API_URL } from '../actions/constants';
import { Link } from 'react-router-dom';

export const PROTOCOL = () => {
    if (global.window) {
        const protocol = window.location.protocol;
        return protocol + '//';
    }
    return '//';
}

export const httpCorrectUrl = (url) => {
    if (url != null) {
        const re = /^(https|http):\/\//;
        return url.replace(re, 'https://');
    }
    return url;
}
export const get_site_url = (props) => {
    let path = (props.influencer.basename ? props.influencer.basename : '') +
        (props.routing.location ? props.routing.location.pathname : '');
    if (global.window) {
        const host = window.location.host;
        const protocol = window.location.protocol;
        const port = window.location.port ? ':' + port : '';
        const url = protocol + '//' + host + port;
        return url + path;
    } else {
        return API_URL() + path;
    }
}
export const getHost = (influencer) => {
    return influencer.website ? influencer.website : (influencer.slogon ? influencer.slogon : "KicksOnFire.com")
}

export const getTitle = (influencer) => {
    return influencer.sitename ? influencer.sitename : (influencer.slogon ? influencer.slogon : "KicksOnFire.com")
}

export const SocialLoginSignup = ({ fbevent, twevent }) => {
    return (
        <div id="social-login-signup">
            <Clearfix />
            <div className="hr-title"><span>or</span></div>
            <ButtonToolbar className="text-center">
                <Button type="button" onClick={fbevent} bsStyle="facebook" >Sign in with Facebook</Button>
                <Button type="button" onClick={twevent} bsStyle="twitter">Sign in with Twitter</Button>
            </ButtonToolbar>
        </div>
    )
}
export const popolarTerms = [
    {slug:'alltime', title: 'All Time'},
    {slug:'weekly', title: 'Week'},
    {slug:'monthly', title: 'Month'},
    {slug:'yearly', title: 'Year'},
    {slug:'upcoming', title: 'Upcoming'},
]

export const loadReCaptcha = () => {
    const is_loaded = document.getElementById( "google-recaptcha");
    if(is_loaded == undefined){
        const siteKey = getReCaptchaSiteKey();
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.id = "google-recaptcha";
        script.type = 'text/javascript';
        script.nonce = "r@nd0m";
        document.body.appendChild(script)
    }
}
export const getReCaptchaSiteKey = () => {
    return '6LeseP4UAAAAAGQ1lrr39C37ZXgTWqBfHUpMBDas';
}

export const executeReCaptcha = (siteKey, action) => {
    if (document.getElementById('g-recaptcha')) {
        document.getElementById('g-recaptcha').remove();
    }
    window.grecaptcha.execute(siteKey, { action })
        .then(token => {
            return token;
        });
}

export const ucfirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const StaticPage = ({ title, content }) => {
    return (
        <Grid className='static-page'>
            <Row>
                <Col sm={12}>
                    <PageHeader>{title}</PageHeader>
                    <div className="page-content">
                        {content}
                    </div>
                    <div><p>&nbsp;</p><p>&nbsp;</p></div>
                </Col>
            </Row>
        </Grid>
    )
}

export const decorateMenuItem = (url, label, key='') => {
    let popular_props = (key == 'popular')?{
        exact: false,
        isActive: (match, location) => {
            const path = (location.pathname.replaceAll('/',' ')).trim().toLowerCase();
            const start = path.split(" ");
            if (start[0] === 'popular'){
                return true;
            }
            return false;
        }
    }:{};
    return <NavLink key={key} activeClassName='active' to={url} {...popular_props}>{label}</NavLink>;
}

export const decorateMenuButton = (url, label, key='', classes = '', props = {}) => {
    let classNames = "btn btn-snkrly";
    if(classes.length){
        classNames += " " + classes;
    }
    if(!props.hasOwnProperty('exact')){
        props.exact = true;
    }
    return <NavLink key={key} activeClassName='btn-snkrly-dark' className={classNames} to={url} {...props} >{label}</NavLink>;
}

export const detectCategoryUrl = (slug) => {
    let url = ('/category/'+slug);
    switch(slug){
        case 'air-jordan':
        case 'nike':
        case 'yeezy':
        case 'sneaker':
            url = ("/" + slug + '-release-dates');
            break;
    }
    return url;
}

export const FormGroupHelper = ({ id, label, help, ...props }) => {
    return (
        <FormGroup controlId={id}>
            {label && <ControlLabel>{label}</ControlLabel>}
            <FormControl id={id} {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    )
}

export const FormatCurrency = (props) => {
    return (
        <FormattedNumber value={props.value} style="currency" currencyDisplay='symbol' currency="USD"  {...props} />
    )
}
export const LoadMorePager = (props) => {
    if(props.pages >1 && props.current < props.pages){
        const nextPage = props.current + 1;
        let path = props.location.pathname;
        if(path == '/error/404'){
            path = '/search'; 
        }
        let search = props.location.search.replace('?','');
        let url_parts = [];
        if(search.length>0){
            url_parts = search.split('&');
            const regex = /page/gi;
            let has_page = false;
            let tmp_parts = url_parts.map((part, index)=>{
                if(regex.test(part)){
                    let x = part.split('=');
                    x[1] = nextPage;
                    has_page = true;
                    part = x.join('=');
                }
                return part;
            });
            url_parts = tmp_parts;
            if(!has_page){
                url_parts.push('page='+nextPage);
            }
        }else{
            url_parts.push('page='+nextPage);
        }
        search = url_parts.join('&');
        const link = path + '?' + search;
        return (
            <>
                <Col lg={12} id="pagination-wrapper">
                    <ul className="pager pager-lg"><li><Link to={link}>More Releases</Link></li></ul>
                    {/* <Pager bsSize="lg"><Pager.Item  onClick={()=>props.setPage(currentPage)}>More Releases</Pager.Item></Pager> */}
                </Col>
                <Clearfix />
            </>
        )
    }
    return <></>;
}

export const SearchResult = (props )=>{
    if(props.keyword && props.keyword.length > 0 && props.total > 0){
        return (<Row>
                    <Col lg={12}>
                        <div id="seachKeyword">
                            <div className="title">"{props.keyword}"</div>
                            <div className="info">Showing {props.total} Results</div>
                        </div>
                    </Col>
                </Row>);
    }
    return <></>
}

export const HomeNavigation = (props )=>{
    let popular_props = {
        exact: false,
        isActive: (match, location) => {
            const path = (location.pathname.replaceAll('/',' ')).trim().toLowerCase();
            const start = path.split(" ");
            if (start[0] === 'popular'){
                return true;
            }
            return false;
        }
    }
    return (
        <Row className='top-nav-sticky'>
            <Col lg={12}>
                <div className="kf-nav-wrapper">
                    <ul className={"nav nav-pills nav-pills-wide four-tabs"}>
                        {decorateMenuButton('/', 'New', 'new', 'btn-block', {exact:true})}
                        {decorateMenuButton('/popular/monthly', 'Popular', 'popular', 'btn-block', popular_props)}
                        {decorateMenuButton('/sneaker-release-dates', 'Upcoming', 'upcoming', 'btn-block', {exact:true})}
                        {decorateMenuButton('/sneaker-release-dates/past', 'Past', 'past', 'btn-block', {exact:true})}
                    </ul>
                </div>
            </Col>
        </Row>
    );
}


export const LoginSignupNavigation = (currentAction)=>{
    let tabItems = [];
    tabItems.push({
      link: '/login', 
      label: 'Login', 
      key: 'login'
    });
    tabItems.push({
      link: '/signup', 
      label: 'Signup', 
      key: 'signup'
    });
    const tabsMenu = tabItems.map((item, index) => {
        item.isActive = currentAction === item.key;
        return <li  key={index} className={item.isActive ? 'active' : ''}><Link to={item.link}>{item.label}</Link></li>
    });
    return (
        <Row>
            <Col lg={12}>
                {/* <div id="login-signup-tabs"> */}
                    <ul className={"nav nav-tabs"}>{tabsMenu}</ul>
                {/* </div> */}
            </Col>
        </Row>
    );
  }

export const ShippingInfoBanner = ({country}) => {
    let info = '$19.95 Shipping on all orders.';
    if(country !== undefined){
        info = shippingInfo(country) + " on all orders."
    }
    return (
        <div id="free-shipping">{info}</div>
    )
}

export const shippingInfo = (country) => {
    let info = '$19.95 Shipping';
    if(country !== undefined){
        switch(country){
            case 'usa':
                info = 'FREE Shipping';
                break;
            case 'canada':
                info = '$9.95 Shipping';
                break;
        }
    }
    return info;
}

export const FormatDate = (props) => {
    if (!props.value) {
        return (
            <div />
        )
    }
    moment.locale('en-us');
    let datetime = moment(props.value).utc();
    if (props.format) {
        datetime = datetime.format(props.format)
    }
    return (
        <div className="formated-date">
            {prefix(props.prefix)}{datetime}
        </div>
    )
}

export const FormatEventDate = (props) => {
    if (!props.value) {
        return '';
    }
    moment.locale('en-us');
    // let datetime = moment(props.value).utc(false);
    let datetime = moment.utc(new Date(props.value),'MM-DD-YYYY');
    if (props.format) {
        datetime = datetime.format(props.format)
    }
    return (
        <time>{datetime}</time>
    )
}

export const dateFormat = (props) => {
    if (!props.value) {
        return '';
    }
    moment.locale('en-us');
    let datetime = moment.utc(props.value,'MM-DD-YYYY').format(props.format);
    return datetime.toString();
}
export const dateFormatReleative = (props) => {
    if (!props.value) {
        return '';
    }
    moment.locale('en-us');
    let dateTime = moment.utc(new Date(props.value),'MM-DD-YYYY');
    let dateTimeNow = moment.utc(new Date(),'MM-DD-YYYY');
    let format = "MMM DD";
    if(dateTimeNow.year() !== dateTime.year()){
        format = "YYYY";
    }
    return dateTime.utc().format("MMM DD")
}

export const isPastOrUpcoming = (value) => {
    const dateTime = moment(new Date(value)).utc();
    const dateTimeNow = moment(new Date()).utc();
    return dateTime > dateTimeNow;
}
export const currentDateFormat = (format) => {
    moment.locale('en-us');
    const dateTimeNow = moment.utc(new Date(),'MM-DD-YYYY');
    return  dateTimeNow.format(format)
}

const prefix = (prefix) => {
    if (prefix) {
        return (
            <span><b>{prefix}</b></span>
        )
    }

}
export const OrderStatuses = () => {
    return {
        payment_received: 'Payment Received',
        completed: 'Completed',
        canceled: 'Canceled',
    };
}


export const smoothScroll = {
    timer: null,
    stop: function () {
        clearTimeout(this.timer);
    },

    scrollTo: function (id, callback) {
        var settings = {
            duration: 500,
            easing: {
                outQuint: function (x, t, b, c, d) {
                    return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
                }
            }
        };
        var percentage;
        var startTime;
        var node = document.getElementById(id);
        var nodeTop = node.offsetTop;
        var nodeHeight = node.offsetHeight;
        var body = document.body;
        var html = document.documentElement;
        var height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );
        var windowHeight = window.innerHeight
        var offset = window.pageYOffset;
        var delta = nodeTop - offset;
        var bottomScrollableY = height - windowHeight;
        var targetY = (bottomScrollableY < delta) ?
            bottomScrollableY - (height - nodeTop - nodeHeight + offset) :
            delta;

        startTime = Date.now();
        percentage = 0;

        if (this.timer) {
            clearInterval(this.timer);
        }

        function step() {
            var yScroll;
            var elapsed = Date.now() - startTime;

            if (elapsed > settings.duration) {
                clearTimeout(this.timer);
            }

            percentage = elapsed / settings.duration;

            if (percentage > 1) {
                clearTimeout(this.timer);

                if (callback) {
                    callback();
                }
            } else {
                yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
                window.scrollTo(0, yScroll);
                this.timer = setTimeout(step, 10);
            }
        }

        this.timer = setTimeout(step, 10);
    }
};

export const overflowOnOff = (onOff = false) => {
    if (onOff) {
        document.getElementsByTagName('body')[0].style.overflow = "hidden";
        document.getElementById('search-box').focus()
    } else {
        document.getElementsByTagName('body')[0].style.removeProperty('overflow');
    }
}


export const getSelectOption = (value, options, name = null) => {
    let option = {};
    if (options.length > 0) {
        options.forEach((element) => {
            if (value == element.value) {
                option = element;
                return option;
            }
        });
        if (!Object.keys(option).length) {
            option = options[0];
        }
    }
    return option;
}




export const page_metas = {
    home: {
        title: 'Home',
        description: 'Transform your site, blog, network, app, influence into a thriving e-commerce business.',
        keywords: 'site, blog, network, app, influence, snkrly, kicks'
    },
    login: {
        title: 'Login',
        description: '',
        keywords: 'login, signup'
    },
    signup: {
        title: 'SignUp',
        description: '',
        keywords: 'login, signup'
    },
    forgot: {
        title: 'Forgot Password',
        description: '',
        keywords: 'login, forgot, password, signup'
    },
    reset: {
        title: 'Reset Password',
        description: '',
        keywords: 'login, reset, forgot, password, signup'
    },
    profile: {
        title: 'My Account',
        description: '',
        keywords: 'profile, account, reset, forgot, password, signup'
    },
    influencer_home: {
        title: 'Home',
        description: 'Transform your site, blog, app,network, influence into a thriving e-commerce business.',
        keywords: 'influencer, site, blog, app,network, influence, e-commerce, business'
    },
    influencer_dashboard: {
        title: 'Dashboard',
        description: '',
        keywords: 'influencer, dashboard'
    },
    influencer_login: {
        title: 'Login',
        description: '',
        keywords: 'influencer, login, signup'
    },
    influencer_signup: {
        title: 'Request Invite',
        description: 'Transform your site, blog, app,network, influence into a thriving e-commerce business.',
        keywords: 'influencer, login, signup, influencer, site, blog, app,network, influence, e-commerce, business'
    },
    influencer_forgot: {
        title: 'Forgot Password',
        description: '',
        keywords: 'influencer, login, forgot, password, signup'
    },
    influencer_reset: {
        title: 'Reset Password',
        description: '',
        keywords: 'influencer, login, reset, forgot, password, signup'
    },
    influencer_profile: {
        title: 'My Account',
        description: '',
        keywords: 'influencer, profile, account, reset, forgot, password, signup'
    },
    influencer_settings: {
        title: 'Settings',
        description: '',
        keywords: 'influencer, profile, account, reset, forgot, password, signup'
    },
    checkout: {
        title: 'Checkout',
        description: 'Checkout',
        keywords: 'checkout, cart, shopping'
    },
    checkout_complete: {
        title: 'Checkout Complete',
        description: 'Checkout Complete',
        keywords: 'checkout, cart, shopping, complete'
    },
    releases: {
        title: 'Checkout',
        description: '',
        keywords: 'checkout, order'
    },
    release: {
        title: 'Snkrly',
        description: '',
        keywords: 'release, event'
    },
    orders: {
        title: 'My Orders',
        description: '',
        keywords: 'order, orders, checkout'
    },
    faq: {
        title: 'FAQ',
        description: 'FAQ',
        keywords: 'FAQ'
    },
    privacy: {
        title: 'PRIVACY POLICY',
        description: 'PRIVACY POLICY',
        keywords: 'PRIVACY, POLICY'
    },
    terms: {
        title: 'Terms of Use',
        description: 'Terms of Use',
        keywords: 'Terms, Use'
    },
    about: {
        title: 'About',
        description: 'About',
        keywords: 'About'
    },
    popular:{
        title: 'Top 100 Trending',
        description: "Explore the pinnacle of sneaker culture with KicksOnFire's Top 100 Sneakers. Curated by our team of experts, this collection showcases the most iconic, stylish, and sought-after sneakers in the game. From timeless classics to the latest must-have releases, our Top 100 Sneakers list is your ultimate guide to the best in footwear. Whether you're a seasoned sneakerhead or just starting your collection, elevate your sneaker rotation with the creme de la creme of sneakers, exclusively curated by KicksOnFire.",
        keywords: 'Top 100, Trending, All Time, Sneakers of 2024'
    },
    latest:{
        title: "What's New",
        description: "Stay ahead in the sneaker game! Discover the latest releases, exclusive drops, and upcoming drops from top brands like Nike, Adidas, Jordan, and more. Our curated selection keeps you informed and ready to cop the freshest sneakers as soon as they hit the market. Check back often to keep your collection fresh and on point.",
        keywords: 'latest, news, buy, latest, marketplace, exclusive, upcoming, Nike, Adidas, Air Jordan, Reebok, Puma'

    }
}
/*
const routers = () => {
    routers.push({
        component: Shop,
        path: '/',
        exact: true,
    });
    routers.push({
        component: Past,
        path: '/past',
    });
    routers.push({
        component: Upcoming,
        path: '/upcoming',
    });
    routers.push({
        component: Search,
        path: '/search',
    });

    routers.push({
        component: Login,
        path: '/login',
        collback: skipIfAuthenticated,
    });
    routers.push({
        component: Signup,
        path: '/signup',
        collback: skipIfAuthenticated,
    });
    routers.push({
        component: Forgot,
        path: '/forgot',
        collback: skipIfAuthenticated,
    });
    routers.push({
        component: Reset,
        path: '/reset/:token',
        collback: skipIfAuthenticated,
    });
    routers.push({
        component: Settings,
        path: '/settings',
        collback: ensureInfluencer,
    });
    routers.push({
        component: InfluencerDashboard,
        path: '/dashboard',
        collback: ensureInfluencer,
    });
    routers.push({
        component: Profile,
        path: '/account',
        collback: ensureAuthenticated,
    });
    routers.push({
        component: Checkout,
        path: '/checkout',
        collback: ensureAuthenticated,
    });
    routers.push({
        component: OrderList,
        path: '/orders',
        collback: ensureAuthenticated,
    });
    routers.push({
        component: OrderView,
        path: '/order/:orderId',
        collback: ensureAuthenticated,
    });
    routers.push({
        component: ReleaseView,
        path: '/:slug',
    });    
}*/