import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Alerts from '../Alerts';
import qs from 'query-string';
import { Pagination, Row, Col, PageHeader, Form, FormControl, ControlLabel, Button, FormGroup, Clearfix, InputGroup } from 'react-bootstrap';
import Moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import OrderItem from './Item';
import { list } from '../../actions/order';
import { setMetatags } from '../../actions/settings';
import PropTypes from 'prop-types';
import { page_metas } from '../Helper';

class OrderList extends Component {
    constructor(props) {
        super(props);
        const metatags = page_metas.orders;
        let queryString = Object.assign({}, qs.parse(this.props.location.search.replace('?', '')));
        const page = queryString.hasOwnProperty("page") && queryString.page > 0 ? queryString.page : 1;
        const orderId = queryString.hasOwnProperty("orderId") ? parseInt(queryString.orderId) : 0;
        const dateFrom = queryString.hasOwnProperty("from") ? queryString.from : '';
        const dateTo = queryString.hasOwnProperty("to") ? queryString.to : '';
        this.state = {
            pageTitle: metatags.title,
            page: page && parseInt(page) > 0 ? parseInt(page) : 1,
            perPage: 24,
            load: 0,
            filters: {
                orderId: orderId > 0 ? orderId : '',
                dateFrom: dateFrom,
                dateTo: dateTo
            },
            datepicker: {
                options: {
                    ranges: {
                        'Today': [Moment(), Moment()],
                        'Yesterday': [Moment().subtract(1, 'days'), Moment().subtract(1, 'days')],
                        'Last 7 Days': [Moment().subtract(6, 'days'), Moment()],
                        'Last 30 Days': [Moment().subtract(29, 'days'), Moment()],
                        'This Month': [Moment().startOf('month'), Moment().endOf('month')],
                        'Last Month': [Moment().subtract(1, 'month').startOf('month'), Moment().subtract(1, 'month').endOf('month')],
                        'Last Year': [Moment().subtract(1, 'year').startOf('year'), Moment().subtract(1, 'year').endOf('year')]
                    },
                    // onEvent: this.handleDateChange(this)
                    onEvent: this.handleDateChange.bind(this),
                    startDate: Moment().subtract(29, 'days'),
                    endDate: Moment(),
                    maxDate: Moment(),
                    applyClass: "btn-snkrly btn-small",
                    cancelClass: "btn-snkrly  btn-small",
                    parentEl: "#snkrly-container",
                    buttonClasses: ["btn"]
                },
                from: Moment().subtract(29, 'days').format('YYYY-MM-DD'),
                to: Moment().format('YYYY-MM-DD')
            }
        };
        this.props.setMetatags(metatags);
        this.handleFetch = this.handleFetch.bind(this);
    }
    componentDidMount() {
        this.handleFetch();
    }

    componentDidUpdate(prevProps, prevState) {
        let cloneCurState = { ...this.state }
        let clonePreState = { ...prevState }
        delete cloneCurState.datepicker;
        delete clonePreState.datepicker;

        if (this.state.load == 1 && JSON.stringify(cloneCurState).toLowerCase().trim() != JSON.stringify(clonePreState).toLowerCase().trim()) {
            this.handlePageChange();
            this.handleFetch();
            this.setState({ load: 0 });
        } else if (prevProps.location.search != this.props.location.search) {
            this.check_for_state_change(this.props.location);
        }
    }

    check_for_state_change(location) {
        const query = Object.assign({}, qs.parse(location.search.replace('?', '')));
        const page = query.hasOwnProperty("page") ? parseInt(query.page) : 1;
        const orderId = query.hasOwnProperty("orderId") ? parseInt(query.orderId) : 0;
        const from = query.hasOwnProperty("from") ? query.from.trim().toLowerCase() : '';
        const to = query.hasOwnProperty("to") ? query.to.trim().toLowerCase() : '';
        let newState = { filters: this.state.filters };
        let filters = Object.assign({}, this.state.filters);
        if (from != this.state.filters.dateFrom.toLowerCase().trim()) {
            filters = Object.assign(filters, { dateFrom: from });
        }
        if (to != this.state.filters.dateTo.toLowerCase().trim()) {
            filters = Object.assign(filters, { dateTo: to });
        }
        if (orderId != parseInt(this.state.filters.orderId)) {
            filters = Object.assign(filters, { orderId: orderId > 0 ? orderId : '' });
        }
        if (page != parseInt(this.state.page)) {
            newState.page = page;
        }
        if (Object.keys(newState).length > 0) {
            this.setState({ page: page, load: 1, filters: filters });
        }
    }

    componentWillUnmount() {
        this.props.clearOrders();
        this.props.clearAlerts();
    }

    handlePageChange1(page = 1) {
        let query = Object.assign({}, qs.parse(this.props.location.search.replace('?', '')), { page: page });
        const searchString = qs.stringify(query);
        this.setState({
            page: page
        });
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
        this.handleFetch(page);
    }

    handlePageChange() {
        let params = {};
        if (this.state.filters.orderId > 0) {
            params.orderId = this.state.filters.orderId;
        }
        if (this.state.filters.dateFrom.length > 0) {
            params.from = this.state.filters.dateFrom;
        }
        if (this.state.filters.dateTo.length > 0) {
            params.to = this.state.filters.dateTo;
        }
        if (this.state.page > 1) {
            params.page = parseInt(this.state.page);
        }
        const query = Object.assign({}, params);
        const searchString = qs.stringify(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
    }

    handleChange(event) {
        let value = event.target.value;
        let name = event.target.name;
        let json = { page: 1, load: 0 };
        let current_state = this.state;
        json.filters = Object.assign({}, current_state.filters, {
            [name]: value
        });
        const state = Object.assign({}, current_state, json);
        this.setState(state);
    }

    handleDateChange(event, picker) {
        let filters = {};
        switch (event.type) {
            case 'apply':
                filters = Object.assign({}, this.state.filters, {
                    dateFrom: picker.startDate.format('YYYY-MM-DD'),
                    dateTo: picker.endDate.format('YYYY-MM-DD')
                });
                this.setState({ page: 1, filters: filters, load: 1 });
                break;
            case 'cancel':
                filters = Object.assign({}, this.state.filters, {
                    dateFrom: '',
                    dateTo: ''
                });
                this.setState({ page: 1, filters: filters, load: 1 });
                break;
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        // this.props.clearAlerts();
        let query = Object.assign({}, qs.parse(this.props.location.search.replace('?', '')));
        if (this.state.filters.orderId > 0) {
            query.orderId = this.state.filters.orderId;
        } else if (query.hasOwnProperty('orderId')) {
            delete query.orderId;
        }
        if (this.state.filters.dateFrom.length > 0) {
            query.from = this.state.filters.dateFrom;
        } else if (query.hasOwnProperty('from')) {
            delete query.from;
        }

        if (this.state.filters.dateTo.length > 0) {
            query.to = this.state.filters.dateTo;
        } else if (query.hasOwnProperty('to')) {
            delete query.to;
        }
        const searchString = qs.stringify(query);
        this.setState({ load: 1 });
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
        // this.handleFetch();
    }
    setPage(page = 1) {
        this.setState({
            page: page,
            load: 1
        })
    }

    handleFetch(page) {
        let body = {
            page: parseInt(page != undefined && page ? page : this.state.page),
            perpage: parseInt(this.state.perPage),
            orderId: parseInt(this.state.filters.orderId),
            dateFrom: this.state.filters.dateFrom,
            dateTo: this.state.filters.dateTo,
        };
        this.props.getOrders(body, this.props.influencer, this.props.token);

    }

    render() {
        const orders = this.props.items.length > 0 ? this.props.items.map((order, index) => {
            return (
                <OrderItem item={order} key={index} />
            );
        }) : [];
        const pagination = this.props.pageCount > 1 ? (
            <Pagination
                prev
                next
                first
                last
                ellipsis
                bsSize="medium"
                boundaryLinks={true}
                maxButtons={3}
                items={this.props.pageCount}
                activePage={parseInt(this.state.page)}
                onSelect={this.setPage.bind(this)} />
        ) : '';
        let daterange = 'Select Date Range';

        if (this.state.filters.dateFrom.length > 0 && this.state.filters.dateTo.length > 0) {
            daterange = `From ${this.state.filters.dateFrom} - To ${this.state.filters.dateTo}`;
        } else if (this.state.filters.dateFrom.length > 0) {
            daterange = `From ${this.state.filters.dateFrom}`;
        } else if (this.state.filters.dateTo.length > 0) {
            daterange = `To ${this.state.filters.dateTo}`;
        }

        return (
            <div id="orders-continer">
                <PageHeader>{this.state.pageTitle}</PageHeader>
                <Alerts alerts={this.props.alerts} />
                <Row id="orders-filters">
                    <Form onSubmit={this.handleSubmit.bind(this)} horizontal>
                        <Col xs={12} sm={4} md={4}>
                            <FormGroup controlId="order-id">
                                <Col componentClass={ControlLabel} className="clear-paddings" xs={2} sm={4} md={3}>
                                    OrderId
                                </Col>
                                <Col xs={10} sm={8} md={9}>
                                    <FormControl placeholder="Enter Order Id" onChange={this.handleChange.bind(this)} id={"order-id"} name={"orderId"} autoComplete={"false"} value={this.state.filters.orderId > 0 ? this.state.filters.orderId : ''} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4}>
                            <FormGroup controlId="date-range">
                                <Col componentClass={ControlLabel} className="clear-paddings" xs={2} sm={4} md={3}>Date</Col>
                                <Col xs={10} sm={8} md={9}>
                                    <DateRangePicker {...this.state.datepicker.options} >
                                        <InputGroup className="pull-right">
                                            <FormControl
                                                value={daterange}
                                                type="text"
                                                id="date-range"
                                                placeholder="Select Date Range"
                                                readOnly aria-describedby="basic-addon2"
                                            />
                                            <InputGroup.Addon id="basic-addon2">&nbsp;<i className="fa fa-calendar" aria-hidden="true" /></InputGroup.Addon>
                                        </InputGroup>
                                    </DateRangePicker>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={4} md={4}>
                            <Button type="submit" bsStyle="snkrly" className="pull-right">Search</Button>
                        </Col>
                    </Form>
                    <Clearfix />
                </Row>
                <Row id="orders-wrapper">
                    {orders}
                </Row>
                <Row>
                    <Col lg={12} id="pagination-wrapper">
                        {pagination}
                    </Col>
                </Row>
                <Clearfix />
            </div>
        );
    }
}
OrderList.contextTypes = {
    router: PropTypes.object.isRequired
}

OrderList.propTypes = {
    location: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        influencer: state.influencer,
        alerts: state.alerts,
        user: state.auth.user,
        // loading: state.settings.loading,
        items: state.orders.items,
        pageCount: state.orders.pageCount,
        page: state.orders.page,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrders: (request, influencer, token) => {
            dispatch(list(request, influencer, token));
        },
        setMetatags: (metatags) => {
            dispatch(setMetatags(metatags));
        },
        clearOrders: () => {
            dispatch({ type: 'CLEAR_ORDERS' });
        },
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderList));
