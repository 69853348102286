'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import { API_HEADERS, AXIOS_CONFIG } from '../../actions/constants';
import Select from 'react-select';
import { Row, Col, ControlLabel, FormGroup, FormControl, Button, Form, Radio, PageHeader, Clearfix, Thumbnail } from 'react-bootstrap';
import { updateProfile, changePassword, deleteAccount } from '../../actions/auth';
import { link, unlink } from '../../actions/oauth';
import { countries, provinces, setMetatags } from '../../actions/settings';
import Alerts from '../Alerts';
import Loader from '../Loader';
import { getSelectOption, page_metas } from '../Helper';

class Profile extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.profile;
    const user = this.props.user;
    this.state = {
      pageTitle: metatags.title,
      email: user.email,
      username: user.username,
      firstname: user.firstname ? user.firstname : '',
      lastname: user.lastname ? user.lastname : '',
      gender: user.gender ? user.gender : '',
      website: user.website ? user.website : '',
      location: user.location ? user.location : '',
      picture: user.picture ? user.picture : '',
      avatar: user.avatar ? user.avatar : '',
      avatar_thumb: user.avatar_thumb ? user.avatar_thumb : '',
      selected_country: 0,
      address: {
        street1: (user.address && user.address.street1) ? user.address.street1 : '',
        street2: (user.address && user.address.street2) ? user.address.street2 : '',
        city: (user.address && user.address.city) ? user.address.city : '',
        postal: (user.address && user.address.postal) ? user.address.postal : '',
        phone: (user.address && user.address.phone) ? user.address.phone : '',
        country: (user.address && user.address.country) ? user.address.country : 840,
        province: (user.address && user.address.province) ? user.address.province : ''
      },
      newAvatar: '',
      password: '',
      confirm: '',
      imgUploading: 0,
      user: user
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    if (props.countries.length == 0) {
      props.getCountries(this.props.token);
    }
    this.props.setMetatags(metatags);
  }

  componentWillUnmount() {
    this.props.clearAlerts();
  }

  componentDidMount() {
    // if (this.props.countries.length == 0) {
    //   this.props.getCountries(this.props.token);
    // }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.loading) {
      let newState = {};
      if (props.user != state.user) {
        newState.newAvatar = '';
        newState.user = props.user;
      }
      if (props.countries.length > 0) {
        const country = parseInt(state.address.country);
        if (state.selected_country != country) {
          newState.selected_country = country;
          props.getProvinces(country, props.token);
        }
      }
      if (Object.keys(newState).length > 0) {
        return newState;
      }
    }
    return null;
  }

  handleChange(event) {
    // event.preventDefault();
    const value = event.target.value;
    const name = event.target.name;

    if (name.indexOf('_') > 0) {
      let json = {};
      let current_state = this.state;
      let splited = name.split('_');
      let s2 = splited[1];
      json.address = Object.assign({}, current_state.address, {
        [s2]: value
      });
      this.setState(json);
    } else {
      this.setState({ [name]: value });
    }
  }

  handleSelectChange(name, event) {
    let json = {};
    const current_state = this.state;
    let splited = name.split('_');
    const s2 = splited[1];
    json.address = Object.assign({}, current_state.address, {
      [s2]: event.value
    });
    this.setState(json);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.clearAlerts();
    this.props.updateProfile(this.state, this.props.influencer, this.props.token);
  }

  handleChangePassword(event) {
    event.preventDefault();
    this.props.clearAlerts();
    this.props.changePassword(this.state.password, this.state.confirm, this.props.influencer, this.props.token);
  }

  handleDeleteAccount(event) {
    event.preventDefault();
    this.props.deleteAccount(this.props.influencer, this.props.token);
  }

  handleLink(provider) {
    this.props.oauthLink(provider, this.props.influencer);
  }

  handleUnlink(provider) {
    this.props.oauthUnLink(provider, this.props.influencer);
  }


  onDrop(files) {
    var data = new FormData();
    data.append('token', this.props.token);
    data.append('userid', this.props.user.id);
    data.append('avatar', files[0]);
    this.props.clearAlerts();
    let _this = this;
    this.setState({ imgUploading: 1 });
    const headers = Object.assign({}, API_HEADERS({}, this.props.token), { "Content-Type": "multipart/form-data", 'Accept': 'application/json' });// [...API_HEADERS({}, this.props.token), ...{"Content-Type": "multipart/form-data"}]
    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: headers,
      url: '/account/avatar',
      data: data
    });
    axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        _this.setState({
          newAvatar: json.data.filename,
          imgUploading: 0,
          avatar_thumb: json.data.url
        });
      } else {
        _this.props.showAlerts('IMAGE_TEMP_FAILURE', res.errors);
      }
    }).catch((error) => {
      console.log(error);
      _this.props.showAlerts('IMAGE_TEMP_FAILURE', [{ msg: 'Error upload images, please try again later.' }]);
    });
    /*request.post('/account / avatar')
      .set('Authorization', `Bearer ${this.props.token}`)
      .set('Accept', 'application/json')
      .send(data)
      .end(function (err, res) {
        if (err) {
          _this.props.showAlerts('IMAGE_TEMP_FAILURE', [{ msg: 'Error upload images, please try again later.' }]);
        } else if (res.body.success) {
          _this.setState({
            newAvatar: res.body.data.filename,
            imgUploading: 0,
            avatar_thumb: res.body.data.url
          });
        } else {
          _this.props.showAlerts('IMAGE_TEMP_FAILURE', res.errors);
        }
      });*/
  }
  drawAvatar() {
    if (this.state.imgUploading) {
      return (
        <Loader />
      )
    } else if (this.state.avatar_thumb) {
      return (
        <Thumbnail src={this.state.avatar_thumb} className="profile" />
      )
    } else {
      return (
        <div>Drop you avatar here</div>
      )
    }
  }

  render() {
    // const facebookLinkedAccount = this.props.user.facebook ? (
    //   <a role="button" className="text-danger" onClick={this.handleUnlink.bind(this, 'facebook')}>Unlink your Facebook account</a>
    // ) : (
    //   <a role="button" onClick={this.handleLink.bind(this, 'facebook')}>Link your Facebook account</a>
    // );
    // const twitterLinkedAccount = this.props.user.twitter ? (
    //   <a role="button" className="text-danger" onClick={this.handleUnlink.bind(this, 'twitter')}>Unlink your Twitter account</a>
    // ) : (
    //   <a role="button" onClick={this.handleLink.bind(this, 'twitter')}>Link your Twitter account</a>
    // );
    // const googleLinkedAccount = this.props.user.google ? (
    //   <a role="button" className="text-danger" onClick={this.handleUnlink.bind(this, 'google')}>Unlink your Google account</a>
    // ) : (
    //   <a role="button" onClick={this.handleLink.bind(this, 'google')}>Link your Google account</a>
    // );
    const countries = this.props.countries.length > 0 ? this.props.countries.map((country, index) => {
      return { value: country.id, label: country.name };
    }) : [];
    const states = this.props.provinces.length > 0 ? this.props.provinces.map((state, index) => {
      return { value: state.id, label: state.name };
    }) : [];

    const selected_country = getSelectOption(this.state.address.country, countries);
    const selected_provice = getSelectOption(this.state.address.province, states);
    return (
      <Row id="page-profile">
        <Alerts alerts={this.props.alerts} />
        <Form onSubmit={this.handleSubmit} horizontal>
          <Col xs={12} sm={12} md={12}>
            <PageHeader>{this.state.pageTitle}</PageHeader>
          </Col>
          <Clearfix />
          <Col xs={12} sm={6} md={6} >
            <FormGroup controlId="email" className="row">
              <Col componentClass={ControlLabel} sm={3}>Email</Col>
              <Col sm={9}>
                <FormControl
                  id='email'
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="username" className="row">
              <Col componentClass={ControlLabel} sm={3}>Username</Col>
              <Col sm={9}>
                <FormControl
                  id='username'
                  name="username"
                  type="text"
                  placeholder="Username"
                  value={this.state.username}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="firstname" className="row">
              <Col componentClass={ControlLabel} sm={3}>First Name</Col>
              <Col sm={9}>
                <FormControl
                  id='firstname'
                  name="firstname"
                  type="text"
                  placeholder="First Name"
                  value={this.state.firstname}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="lastname" className="row">
              <Col componentClass={ControlLabel} sm={3}>Last Name</Col>
              <Col sm={9}>
                <FormControl
                  id='lastname'
                  name="lastname"
                  type="text"
                  placeholder="Last Name"
                  value={this.state.lastname}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="gender" className="row">
              <Col componentClass={ControlLabel} sm={3}>Gender</Col>
              <Col sm={9}>
                <Radio inline
                  id="gender-male"
                  name="gender"
                  checked={"male" === this.state.gender}
                  value="male"
                  onChange={this.handleChange}>Male</Radio>
                <Radio inline
                  id="gender-female"
                  name="gender"
                  value="female"
                  checked={"female" === this.state.gender}
                  onChange={this.handleChange}>Female</Radio>
              </Col>
            </FormGroup>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <FormGroup className="row">
              <Col componentClass={ControlLabel} sm={3}>Profile Image</Col>
              <Col sm={4}>
                <FormControl.Static componentClass='div'>
                  <Dropzone accept="image/jpeg, image/png, image/jpg, , image/gif"
                    style={{ display: 'inline-block', minWidth: '50px', minHeight: '50px', width: 'auto', height: 'auto', border: '1px dashed grey' }}
                    onDrop={this.onDrop.bind(this)} multiple={false} children={this.drawAvatar.bind(this)}>
                  </Dropzone>
                </FormControl.Static>
              </Col>
            </FormGroup>
          </Col>
          <Clearfix />
          <Col xs={12} sm={6} md={6} >
            <FormGroup controlId="address_street1" className="row">
              <Col componentClass={ControlLabel} sm={3}>Address </Col>
              <Col sm={9}>
                <FormControl
                  id='address_street1'
                  name="address_street1"
                  type="text"
                  placeholder="Street Address or P.O. Box"
                  value={this.state.address.street1}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="address_street2" className="row">
              <Col componentClass={ControlLabel} sm={3}>Address 2</Col>
              <Col sm={9}>
                <FormControl
                  id='address_street2'
                  name="address_street2"
                  type="text"
                  placeholder="Apt, Suite, Unit, Building, Floor"
                  value={this.state.address.street2}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="address_city" className="row">
              <Col componentClass={ControlLabel} sm={3}>City</Col>
              <Col sm={9}>
                <FormControl
                  id='address_city'
                  name="address_city"
                  type="text"
                  placeholder="City"
                  value={this.state.address.city}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup controlId="address_postal" className="row">
              <Col componentClass={ControlLabel} sm={3}>Postal Code</Col>
              <Col sm={9}>
                <FormControl
                  id='address_postal'
                  name="address_postal"
                  type="text"
                  placeholder="Postal Code"
                  value={this.state.address.postal}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>

          </Col>
          <Col xs={12} sm={6} md={6} >
            <FormGroup controlId="address_country" className="row">
              <Col componentClass={ControlLabel} sm={3}>Country</Col>
              <Col sm={9}>
                <Select
                  id='address_country'
                  name="address_country"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable={false}
                  defaultValue={selected_country}
                  value={selected_country}
                  onChange={this.handleSelectChange.bind(this, 'address_country')}
                  options={countries}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="address_province" className="row">
              <Col componentClass={ControlLabel} sm={3}>State</Col>
              <Col sm={9}>
                <Select
                  id='address_province'
                  name="address_province"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable={false}
                  defaultValue={selected_provice}
                  value={selected_provice}
                  onChange={this.handleSelectChange.bind(this, 'address_province')}
                  options={states}
                />
              </Col>
            </FormGroup>
            <FormGroup controlId="address_phone" className="row">
              <Col componentClass={ControlLabel} sm={3}>Phone Number</Col>
              <Col sm={9}>
                <FormControl
                  id='address_phone'
                  name="address_phone"
                  type="text"
                  placeholder="Phone Number"
                  value={this.state.address.phone}
                  onChange={this.handleChange} />
              </Col>
            </FormGroup>
            <FormGroup className="row">
              <Col xs={12} sm={12} md={12} >
                <Button type="submit" bsStyle="snkrly" className="pull-right">Update Profile</Button>
              </Col>
            </FormGroup>
          </Col>
          <Clearfix />
        </Form>
        <hr />
        <Col xs={12} sm={6} md={6}>
          <Form onSubmit={this.handleChangePassword} horizontal className="row clearfix">
            <Col xs={12} sm={12} md={12}>
              <h2>Change Password</h2>
            </Col>
            <Clearfix />
            <Col xs={12} sm={12} md={12} >
              <FormGroup controlId="password" className="row">
                <Col componentClass={ControlLabel} sm={3}>New password</Col>
                <Col sm={9}>
                  <FormControl
                    id='password'
                    name="password"
                    type="password"
                    placeholder="New password"
                    autocomplate='off'
                    value={this.state.password}
                    onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup controlId="confirm" className="row">
                <Col componentClass={ControlLabel} sm={3}>Confirm password</Col>
                <Col sm={9}>
                  <FormControl
                    id='confirm'
                    name="confirm"
                    type="password"
                    placeholder="Confirm password"
                    autocomplate='off'
                    value={this.state.confirm}
                    onChange={this.handleChange} />
                </Col>
              </FormGroup>
              <FormGroup className="pull-right">
                <Button type="submit" bsStyle="snkrly">Change Password</Button>
              </FormGroup>
            </Col>
          </Form>
          <hr className="visible-xs" />
        </Col>
        {/* <Col xs={12} sm={6} md={6} >
          <Form>
            <h2>Linked Accounts</h2>
            <FormGroup>
              <p>{facebookLinkedAccount}</p>
              <p>{twitterLinkedAccount}</p>
            </FormGroup>
          </Form>
        </Col> */}
        {/*<div className="panel">
          <div className="panel-body">
            <form onSubmit={this.handleDeleteAccount.bind(this)} className="form-horizontal">
              <legend>Delete Account</legend>
              <div className="form-group">
                <p className="col-sm-offset-3 col-sm-9">You can delete your account, but keep in mind this action is irreversible.</p>
                <div className="col-sm-offset-3 col-sm-9">
                  <button type="submit" className="btn btn-danger">Delete my account</button>
                </div>
              </div>
            </form>
          </div>
        </div>*/}
      </Row >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    alerts: state.alerts,
    loading: state.settings.loading,
    countries: state.settings.countries,
    provinces: state.settings.provinces,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCountries: (token) => {
      dispatch(countries(token));
    },
    getProvinces: (country, token) => {
      dispatch(provinces(country, token));
    },
    oauthLink: (provider, influencer) => {
      dispatch(link(provider, influencer));
    },
    deleteAccount: (influencer, token) => {
      dispatch(deleteAccount(influencer, token));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    oauthUnLink: (provider, influencer) => {
      dispatch(unlink(provider, influencer));
    },
    updateProfile: (state, influencer, token) => {
      dispatch(updateProfile(state, influencer, token));
    },
    changePassword: (password, confirm, influencer, token) => {
      dispatch(changePassword(password, confirm, influencer, token));
    },
    showAlerts: (type, alerts) => {
      dispatch({ type: type, alerts: alerts });
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
