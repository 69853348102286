import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Row, Col, PageHeader } from 'react-bootstrap';
import Alerts from '../Alerts';
import ReleaseItem from './Item';
import { popular } from '../../actions/release';
import { decorateMenuButton, popolarTerms, HomeNavigation, page_metas } from '../Helper';
import PropTypes from 'prop-types'
import Breadcrumb from '../Breadcrumb';

class PopularComponent extends Component {

    constructor(props) {
        super(props);
        const showReleaseDate = props.hasOwnProperty("showReleaseDate") && props.showReleaseDate != undefined ? props.showReleaseDate : false;
        const hypeType = props.hasOwnProperty("hypeType") && props.hypeType != undefined ? props.hypeType : '';
        this.state = {
            hypeType: hypeType,
            showloader: false,
            showReleaseDate: showReleaseDate,
        };
    }

    componentDidMount() {
        if(!this.props.isServer){
            this.handleFetch();
        }else{
            this.props.clearServerLoad();
        }
    }

    componentWillUnmount() {
        this.props.clearReleases();
        this.props.clearAlerts();
    }

    componentDidUpdate(prevProps, prevState) {
        let cloneCurState = { ...this.state }
        let clonePreState = { ...prevState }

        cloneCurState.columnFilterShow = false;
        clonePreState.columnFilterShow = false;
        cloneCurState.showloader = false;
        clonePreState.showloader = false;
        if (JSON.stringify(cloneCurState).toLowerCase().trim() != JSON.stringify(clonePreState).toLowerCase().trim()) {
            this.handlePageChange();
            this.handleFetch();
        }else if (JSON.stringify({pathname: prevProps.location.pathname, search: prevProps.location.search}).toLowerCase().trim() !== JSON.stringify({pathname: this.props.location.pathname, search: this.props.location.search}).toLowerCase().trim()) {
            this.check_for_state_change(this.props);
        }
    }

    check_for_state_change(props) {
        const hypeType = props.match.params['slug'].toLowerCase();
        let newState = {};
        if(hypeType !== this.state.hypeType){
            newState.hypeType = hypeType;
        }        
        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    handlePageChange() {
        let params = {};
        const query = Object.assign({}, params);
        const searchString = qs.stringify(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
    }

    handleFetch() {
        this.props.clearAlerts();
        let body = {
            hypetype: this.state.hypeType,
        };
        this.props.defaultReleases(body, true, this.props.influencer);
    }   

    localNavigation(){
        const navLink = '/popular/';
        const navigationItems = popolarTerms.map((item, index) => {
            const url = navLink+item.slug;
            return (
                decorateMenuButton(url, item.title, index, 'btn-block')
            );
        });
        return (
            <Row>
                <Col lg={12}>
                    <div className="kf-nav-wrapper">
                        <ul className="nav nav-pills nav-pills-wide five-tabs">
                            {navigationItems}
                        </ul>
                    </div>
                </Col>
            </Row>
        );
    }

    render() {
        const country = this.props.country;
        const releases = this.props.items.length > 0 ? this.props.items.map((release, index) => {
            return (
                <ReleaseItem item={release} key={index} country={country} />
            );
        }) : this.props.noitems ? (
            <Col lg={12} className="noitems">No items found.</Col>
        ) : '';
        const description = page_metas.popular?.description?page_metas.popular.description:'';
        let breadcrumb = [];
        if(this.state.hypeType.length){
            breadcrumb.push({
                title: this.props?.metatags?.title,
                url: '/popular/' + this.state.hypeType
            });
        }
        return (
            <div id="page-releases" className="popular">
                <Breadcrumb items={breadcrumb} />
                <Alerts alerts={this.props.alerts} />
                { HomeNavigation({routing : this.props.routing}) }
                <PageHeader bsClass="md" className="page-title">{this.props?.metatags?.title}</PageHeader>
                {description.length>0?<div className='description' dangerouslySetInnerHTML={{__html: description}} />:''}
                {this.localNavigation()}
                <Row id="row-container">
                    <div className="releases-container">
                        {releases}
                        <div className='clearfix' />
                    </div>
                </Row>
            </div>
        );
    }
}

PopularComponent.contextTypes = {
    router: PropTypes.object.isRequired
}

PopularComponent.propTypes = {
    location: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    let props = {
        alerts: state.alerts,
        influencer: state.influencer,
        items: state.releases.items,
        metatags: state.metatags,
        noitems: state.settings.noitems,
        isServer: state.settings.isServer,
        routing: state.routing,
        country: state.settings.location ? state.settings.location.snkrly_country_code : '',
    };
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        defaultReleases: (request, showloader, influencer) => {
            dispatch(popular(request, showloader, influencer));
        },
        clearReleases: () => {
            dispatch({ type: 'CLEAR_RELEASES' });
        },
        clearServerLoad: () => {
            dispatch({ type: 'SERVER_LOAD_END' });
        },        
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}

const ReleasePopular = (props) => {
    const slug = props.match.params['slug'];
    return (
        <Releases hypeType={slug} showReleaseDate={false} {...props} />
    )
}

const Releases = withRouter(connect(mapStateToProps, mapDispatchToProps)(PopularComponent));
export const Popular = withRouter(ReleasePopular);

