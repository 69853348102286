'use strict';
import capitalize from 'capitalize';
import {dateFormat, currentDateFormat, page_metas} from '../components/Helper';
import moment from 'moment';
const initialState = {
  title: "",
  description: '', // "Shop your favourite sneakers. Find your Grails. Our mission is to provide an accessible information platform for the sneaker community and tools to facilitate the shopping experience for any footwear consumer. Whether you're looking for a release date, price or the sneaker itself, we're here to help! We cover most brands such as Adidas, Air Jordan, New Balance, Nike, Puma, Reebok, Supra, Under Armour, Vans and more.",
  keywords: 'free shipping,releases, sneakers, kicks, kix, upcoming, past, recent',
  image: ''
};


export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = Object.assign({}, initialState, state, { hydrated: true });
  }

  switch (action.type) {
    case 'RELEASE_LIST_SUCCESS':
      let release_list_metatags = {
        title: 'Shop'
      };

      if(action.hasOwnProperty('page')){
        release_list_metatags.page = action.page;
      }
      
      switch (action.api) {
        case 'under_retail':
          release_list_metatags.title = "Under Retail";
          break;
        case 'search':
          release_list_metatags.title = "Search";
          break;
        case 'shop':
        case 'priceguide':
        case 'list':
          release_list_metatags.title = "Shop";
          break;
        case 'latest':
          release_list_metatags.title = page_metas.latest.title;
          release_list_metatags.description = page_metas.latest.description;
          release_list_metatags.keywords = page_metas.latest.keywords;
          break;
        case 'category':
        case 'tag':
            if(action.hasOwnProperty('brandOrTag')){
                if(action.brandOrTag.name.length>0){
                  action.brandOrTag.name = capitalize.words(action.brandOrTag.name);
                  release_list_metatags.title = action.brandOrTag.name;
                  if(action.hasOwnProperty('releaseType') && action.releaseType !== undefined){
                    switch(action.releaseType.toLowerCase()){
                        case 'upcoming':
                          release_list_metatags.title += ' - Release Dates (Upcoming)';
                          action.brandOrTag.name += ' Release Dates';
                          break;
                        case 'past':   
                          release_list_metatags.title += ' - Past Releases';
                          action.brandOrTag.name += ' - Past Releases';
                          break;
                        default:
                          const dateTimeNow = moment.utc(new Date(),'MM-DD-YYYY');
                          const currentYear = dateTimeNow.format("YYYY");
                          release_list_metatags.title += ': Ultimate Guide '+ currentYear +' - Exclusive Drops & Release Dates'
                          break;    
                    }
                  }
                  release_list_metatags.title = capitalize.words(release_list_metatags.title)
                  if(action.brandOrTag?.description && action.brandOrTag.description.length>0){
                    release_list_metatags.description = action.brandOrTag.description.replace(/(<([^>]+)>)/gi, "");
                }                  
              }
            }
            break;
      }
      if(action.hasOwnProperty('image') && action.image.length>0){
        release_list_metatags.image = action.image;
      }
      if(!release_list_metatags.hasOwnProperty('image') && action.items?.length>0){
        release_list_metatags.image = action.items[0].image;
      }
      if(release_list_metatags?.description && release_list_metatags.description.length > 315){
        release_list_metatags.description = release_list_metatags.description.substr(0, 315)+ "...";
      }
      return Object.assign({}, state, release_list_metatags);
    case 'RELEASE_VIEW_SUCCESS':
      const release = Object.assign({}, action.item.release);
      let meta_title = release.title;
      let meta_keywords = [release.title];
      let meta_description = '';
      if (release.type[0].brand_name) {
        meta_keywords.push(release.type[0].brand_name);
      }
      if(release.tags){
        release.tags.forEach(element => {
          meta_keywords.push(element.name);
        });
      }
      if(release.event_date){
        meta_title += ' - ' + dateFormat({value: release.event_date, format: "MMM YYYY"});
      }
      if (release.style) {
        meta_keywords.push(release.style);
      }
      if (release.style_code) {
        meta_keywords.push(release.style_code);
        meta_title += ' - ' + release.style_code;
      }
      if (release.description) {
        meta_description += release.description.replace(/(<([^>]+)>)/gi, "");
      }
      if(meta_description.length > 315){
        meta_description = meta_description.substr(0, 315)+ "...";
      }
      let release_view_metatags = {
          title: meta_title,
          description: meta_description,
          keywords: meta_keywords.join(","),
          image: release.master_image,
      };      
      return Object.assign({}, state, release_view_metatags);
    case 'RELEASE_VIEW_FAILURE':
    case 'RELEASE_LIST_FAILURE':
    case 'RELEASE_CATEGORYTAG_FAILURE':
      let release_fail_metatags = {};
      if(action.hasOwnProperty('statusCode')){
        const statusCode = action.statusCode;
        if(statusCode == 404){
          release_fail_metatags = {
            title: '404 Error',
          };
        }
      }
      return Object.assign({}, state, release_fail_metatags);      
    case 'POPULAR_LIST_SUCCESS':
      let popular_metatags = Object.assign({},page_metas.popular);
      switch (action.hypetype) {
          case 'weekly':
            popular_metatags.title = 'Top 100 Sneakers - This Week';
            break;
          case 'monthly':
            popular_metatags.title = ('Top 100 Sneakers - '+ currentDateFormat('MMMM YYYY'));
            break;
          case 'yearly':
            popular_metatags.title = ('Top 100 Sneakers of '+ currentDateFormat('YYYY'));
            break;
          case 'upcoming':
            popular_metatags.title = ('Top 100 Upcoming Sneakers');
            break;
          case 'alltime':
            popular_metatags.title = ('Top 100 Sneakers - All Time');
            break;
      }
      if(popular_metatags.description.length > 315){
        popular_metatags.description = popular_metatags.description.substr(0, 315)+ "...";
      }
      if(action.hasOwnProperty('image') && action.image.length>0){
        popular_metatags.image = action.image;
      }
      if(action.items?.length>0){
        popular_metatags.image = action.items[0].image;
      }
      return Object.assign({}, state, popular_metatags);
    case 'ORDER_LIST_SUCCESS':
    case 'ORDER_VIEW_SUCCESS':
    case 'METATAGS_SUCCESS':
      return Object.assign({}, state, action.metatags);
    default:
      return state;
  }
}
