'use strict';
import {routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux';
import alerts from './alerts';
import auth from './auth';
import release from './release';
import releases from './releases';
import orders from './orders';
import order from './order';
import influencer from './influencer';
import cart from './cart';
import productsizes from './productsizes';
import settings from './settings';
import metatags from './metatags';

export default combineReducers({
  alerts,
  auth,
  release,
  releases,
  orders,
  order,
  influencer,
  cart,
  productsizes,
  settings,
  metatags,
  routing: routerReducer
});