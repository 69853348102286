'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { page_metas, StaticPage } from '../Helper';
import { setMetatags } from '../../actions/settings';
import capitalize from 'capitalize';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = { pageTitle: "FAQ" };
    const metatags = page_metas.faq;
    this.props.setMetatags(metatags);
    this.getConntent = this.getConntent.bind(this);
  }
  getConntent() {
    const content =
      <div>
        <h3>Are the shoes authentic?</h3>
        <p>100% Authentic Guaranteed. It is with great pleasure that we introduce a hassle-free sneaker shopping experience for you. All sneakers purchased are guaranteed authentic. We strive every day to create a rich seamless experience for sneakerheads. We are driven by our principles and committed to providing the best selection, prices, and customer service.</p>
        <h3>What is the condition of items sold on KicksOnFire?</h3>
        <p>Sneakers sold on KicksOnFire are considered "deadstock," which means that they're new and unworn at the time of sale.</p>
        <h3>How do I contact customer support regarding my order?</h3>
        <p>Our customer support team is diligently working around the clock to assure a seamless delivery for every order. To reach a support agent, simply log into your account and go to My Orders from the Menu. Select the order and a chat window will connect you to your personal assistant who can help you.</p>
        <h3>What is the return policy?</h3>
        <p>We do not allow any refunds, exchanges or swap back. If something is wrong with your order caused by our end, please contact us through the order portal and we'll help you promptly. If there is a problem with an item you received, you must contact us in writing within 3 days after receiving the item with a detailed description of the problem. Orders cannot be cancelled after being placed. Our fulfilment centers are very quick to process orders and cannot accommodate cancelled orders.</p>
        <h3>How do I track my order?</h3>
        <p>Once an order is placed, our fulfillment center prepares and packages your order. Once your package is ready to be shipped,  a shipping label with a tracking number is created. This process may take 1-3 business days based on the product and demand.</p>
        <p>You will be able to see the tracking number in your Order Details as soon as the label is created. Please note, the tracking number is not activated and updates may not appear for another 24-48 hours depending on the postal carrier.</p>
        <p>Once a tracking number is available and active, you can periodically check your order by clicking on the tracking number to view shipment updates. We will also send you notifications regarding your order and its journey via push notifications or email.</p>
        <h3>Do you ship worldwide?</h3>
        <p>We ship worldwide. If you're on planet earth, we'll find a way to deliver your sneakers.</p>
        <h3>How do I cancel my order?</h3>
        <p>Orders cannot be canceled after being placed. Our fulfillment centers are very quick to process orders and cannot accommodate canceled orders.</p>
        <h3>Why was my order canceled?</h3>
        <p>In some rare cases, we may cancel your order on our end. Before an item is shipped, it must pass our multi-point inspection check. If at any given stage, we find any issue with the product, we take it upon ourselves to cancel the order and refund you fully to ensure we don't ship an item that you're not going to be 100% satisfied with.</p>
        <p>When an order is canceled, the refund is automatically deposited back into your Paypal account. Note: Even if your initial payment was made through a card on file in your Paypal account, the refunded amount will be sent back to your Paypal account and not your card.</p>
        <p>Additionally, our orders are fulfilled by various warehouses across the globe. We would encourage you to place the order once again. We'll try our best to reroute your order to another center and get you your item fast. Be advised, prices may vary.</p>
        <h3>Are custom fees included on International orders?</h3>
        <p>Buyers are responsible for the duties placed by customs during delivery. Unfortunately, this is completely out of our control.</p>
        <h3>Are the sizes in US or UK sizes?</h3>
        <p>All sizes displayed are US sizes and should be converted accordingly to your sizing metrics before ordering.</p>
        <h3>Are all prices in US Dollars?</h3>
        <p>All prices are in United States Dollars. However, we use Paypal as our payment processor and you will be able to pay using any currency via the daily foreign exchange rate calculated by Paypal at checkout.</p>
      </div>;
    return content;

  }

  render() {
    return (
      <StaticPage
        title={this.state.pageTitle}
        content={this.getConntent()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    influencer: state.influencer ? state.influencer : {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));
