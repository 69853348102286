'use strict';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import configureStore from './store/configureStore';
import { getRoutes } from './routes';
import { createBrowserHistory } from 'history';
import ReactPixel from 'react-facebook-pixel';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import daterangepicker from 'bootstrap-daterangepicker/daterangepicker.css';
import imageGallery from 'react-image-gallery/styles/css/image-gallery.css';

// import bootstrap from 'bootstrap/dist/css/bootstrap.min.css';
// import reactSelect from 'react-select/dist/react-select.css';
// import quillStyles from 'react-quill/dist/quill.snow.css';

// import Analytics from 'react-router-ga';

// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-114936533-1');

// ReactPixel.init('543688192660469');
ReactPixel.init('276331313863717');

const initialStore = window.INITIAL_STATE;
const basepath = initialStore.influencer.basename !== '' ? initialStore.influencer.basename : '/';
const history = createBrowserHistory({ basename: basepath });
const store = configureStore(initialStore, history);
window.dataLayer = window.dataLayer || [];
window.gtag = function () {
    dataLayer.push(arguments);
}
window.gtag('js', new Date());
window.gtag('config', 'G-FHPXNQ7RZQ');

const AppRouter = (props) => {
    return (
        <HelmetProvider>
            <Provider {...props} store={store} key="provider">
                <ConnectedRouter {...props} store={store} history={history}>
                    {getRoutes(store)}
                </ConnectedRouter>
            </Provider> 
        </HelmetProvider>
    )
}

const rootElement = document.getElementById('app');
if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<AppRouter />, rootElement);
} else {
    ReactDOM.render(<AppRouter />, rootElement);
}
// ReactDOM.hydrate(<AppRouter />, document.getElementById('app'));