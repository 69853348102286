'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateProfile } from '../../actions/influencer';
import { setMetatags } from '../../actions/settings';
import Alerts from '../Alerts';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import { API_HEADERS, AXIOS_CONFIG } from '../../actions/constants';
import Loader from '../Loader';
import { page_metas, FormGroupHelper } from '../Helper';
import { Row, Col, Clearfix, PageHeader, Form, FormControl, Thumbnail, Button, FormGroup, ControlLabel } from 'react-bootstrap';
// import ReactQuill from 'react-quill';
// const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

class Settings extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.influencer_settings;
    this.state = {
      pageTitle: metatags.title,
      id: props.user.id,
      logo: props.influencer.logo ? props.influencer.logo : '',
      influencer_logo: props.influencer.influencer_logo ? props.influencer.influencer_logo : '',
      slogon: props.influencer.slogon ? props.influencer.slogon : '',
      about: props.influencer.about ? props.influencer.about : '',
      subdomain: props.influencer.subdomain ? props.influencer.subdomain : '',
      paypalemail: props.influencer.paypalemail ? props.influencer.paypalemail : '',
      username: props.user.username ? props.user.username : '',
      phonenumber: props.influencer.phonenumber ? props.influencer.phonenumber : '',
      website: props.influencer.website ? props.influencer.website : '',
      sitename: props.influencer.sitename ? props.influencer.sitename : '',
      socials: props.influencer.socials,
      analytics: props.influencer.analytics,
      settings: props.influencer.settings,
      email: props.user.email ? props.user.email : '',
      newLogo: '',
      imgUploading: 0,
    };
    props.setMetatags(metatags);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.props.clearAlerts();
  }

  static getDerivedStateFromProps1(props, state) {
    if (!props.loading) {
      let newState = {};
      if (props.influencer.logo != state.logo) {
        newState.logo = props.influencer.logo;
      }
      if (props.influencer.influencer_logo != state.influencer_logo) {
        newState.influencer_logo = props.influencer.influencer_logo;
      }
      if (props.influencer.slogon != state.slogon) {
        newState.slogon = props.influencer.slogon;
      }
      if (props.influencer.paypalemail && props.influencer.paypalemail != state.paypalemail) {
        newState.paypalemail = props.influencer.paypalemail;
      }
      if (props.influencer.phonenumber != state.phonenumber) {
        newState.phonenumber = props.influencer.phonenumber;
      }
      if (props.influencer.website != state.website) {
        newState.website = props.influencer.website;
      }
      if (props.influencer.sitename != state.sitename) {
        newState.sitename = props.influencer.sitename;
      }
      if (props.influencer.socials != state.socials) {
        newState.socials = props.influencer.socials;
      }
      if (props.influencer.settings != state.settings) {
        newState.settings = props.influencer.settings;
      }
      if (props.influencer.analytics != state.analytics) {
        newState.analytics = props.influencer.analytics;
      }
      if (newState.newLogo !== '') {
        newState.newLogo = '';
      }
      if (Object.keys(newState).length > 0) {
        return newState;
      }
    }
    return null;
  }

  handleChangeEditor(content, delta, source, editor) {
    const textLength = editor.getLength();
    if (textLength) {
      this.setState({ about: editor.getText() });
    }
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    if (name.indexOf('_') > 0) {
      // let current_state = this.state;
      let splited = name.split('_');
      let s1 = splited[0];
      let s2 = splited[1];
      // let json = {};
      this.state[s1][s2] = value;
      this.setState(this.state);
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.updateProfile(this.state, this.props.token);
  }

  onDrop(files) {
    var data = new FormData();
    data.append('token', this.props.token);
    data.append('userid', this.props.user.id);
    data.append('logo', files[0]);

    let _this = this;
    this.setState({ imgUploading: 1 });
    const headers = Object.assign({}, API_HEADERS({}, this.props.token), { "Content-Type": "multipart/form-data", 'Accept': 'application/json' });// [...API_HEADERS({}, this.props.token), ...{"Content-Type": "multipart/form-data"}]
    const options = Object.assign({}, AXIOS_CONFIG, {
      headers: headers,
      url: '/influencer/logo',
      data: data
    });
    axios(options).then((response) => {
      const json = response.data;
      if (json.success) {
        _this.setState({
          newLogo: json.data.filename,
          imgUploading: 0,
          influencer_logo: json.data.url
        });
      } else {
        _this.props.showAlerts('IMAGE_TEMP_FAILURE', res.errors);
      }
    }).catch((error) => {
      console.log(error);
      _this.props.showAlerts('IMAGE_TEMP_FAILURE', [{ msg: 'Error upload images, please try again later.' }]);
    });


    /*request.post('/influencer/logo')
      .set('Authorization', `Bearer ${this.props.token}`)
      .send(data)
      .end(function (err, res) {
        _this.setState({
          newLogo: res.body.data.filename,
          imgUploading: 0,
          influencer_logo: res.body.data.url
        });
      });*/
  }
  drawLogo() {
    if (this.state.imgUploading) {
      return (
        <Loader />
      )
    } else if (this.state.influencer_logo) {
      return (
        <Thumbnail src={this.state.influencer_logo} width="100" className="profile" />
      )
    } else {
      return (
        <div>Drop you avatar here</div>
      )
    }
  }

  render() {
    const analytics_hidden = this.props.influencer.subdomain == 'kicksonfire' ? '' : 'hidden';
    return (
      <Row id="influencer-prifile">
        <Col xs={12} sm={12} md={12}>
          <PageHeader>{this.state.id ? this.state.pageTitle : 'Become Influencer'}</PageHeader>
          <Alerts alerts={this.props.alerts} />
        </Col>
        <Form onSubmit={this.handleSubmit.bind(this)}>
          <Col xs={12} sm={9} md={9}>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <FormGroupHelper
                  type="text"
                  name="subdomain"
                  disabled
                  id="subdomain"
                  label="Subdomain"
                  value={this.state.subdomain}
                  onChange={this.handleChange.bind(this)}
                />
                <FormGroupHelper
                  type="text"
                  name="website"
                  id="website"
                  label="Website Domain"
                  value={this.state.website}
                  onChange={this.handleChange.bind(this)}
                />
                <FormGroupHelper
                  type="text"
                  name="sitename"
                  id="sitename"
                  label="Site Name"
                  value={this.state.sitename}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={6} md={6}>
                <FormGroupHelper
                  type="text"
                  name="slogon"
                  id="slogon"
                  label="Slogon"
                  value={this.state.slogon}
                  onChange={this.handleChange.bind(this)}
                />
                <FormGroupHelper
                  type="email"
                  name="paypalemail"
                  id="paypalemail"
                  label="Paypal Email"
                  value={this.state.paypalemail}
                  onChange={this.handleChange.bind(this)}
                />
                <FormGroupHelper
                  type="text"
                  name="phonenumber"
                  id="phonenumber"
                  label="Phone Number"
                  value={this.state.phonenumber}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              {/* {(typeof global.window == 'object') ? */}
              <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  // type="textarea"
                  componentClass="textarea"
                  name="about"
                  rows={3}
                  id="about"
                  label="About Your Store"
                  value={this.state.about}
                  onChange={this.handleChange.bind(this)}
                />
                {/* <FormGroup controlId='about'> */}
                {/* <ControlLabel>About Your Store</ControlLabel> */}
                {/* <Form.Control name="about" id="about" as="textarea" value={this.state.about} rows={3} /> */}
                {/* <ReactQuill
                      ref="about"
                      name="about"
                      id="about"
                      defaultValue={this.state.about}
                      style={{ minHeight: '200px' }}
                      onChange={this.handleChangeEditor.bind(this)} /> */}
                {/* </FormGroup> */}
              </Col>
              {/* : ''} */}
            </Row>
          </Col>
          <Col xs={12} sm={3} md={3}>
            <FormGroup>
              <ControlLabel>Logo</ControlLabel>
              <FormControl.Static componentClass='div'>
                <Dropzone accept="image/jpeg, image/png, image/jpg, , image/gif"
                  style={{ display: 'inline-block', minWidth: '30px', minHeight: '30px', width: 'auto', height: 'auto', border: '1px dashed grey' }}
                  onDrop={this.onDrop.bind(this)} multiple={false} children={this.drawLogo.bind(this)}>
                </Dropzone>
              </FormControl.Static>
            </FormGroup>
          </Col>
          <Clearfix />
          <Col xs={12} sm={6} md={6}>
            <legend>Socials</legend>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="socials_facebook"
                  id="socials-facebook"
                  label="Facebook"
                  value={this.state.socials.facebook}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="socials_twitter"
                  id="socials-twitter"
                  label="Twitter"
                  value={this.state.socials.twitter}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="socials_instagram"
                  id="socials-instagram"
                  label="Instagram"
                  value={this.state.socials.instagram}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="socials_youtube"
                  id="socials-youtube"
                  label="Youtube"
                  value={this.state.socials.youtube}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              {/* <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="socials_google"
                  id="socials-google"
                  label="Google"
                  value={this.state.socials.google}
                  onChange={this.handleChange.bind(this)}
                />
              </Col> */}
            </Row>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <legend>Analytics</legend>
            <Row>
              <Col xs={12} sm={12} md={12} className={analytics_hidden}>
                <FormGroupHelper
                  type="text"
                  name="analytics_google"
                  id="analytics-google"
                  label="Google Analytics Code"
                  placeholder='UA-XXXXXXXXX-X'
                  value={this.state.analytics.google}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={6} md={6}>
                <FormGroupHelper
                  type="text"
                  name="analytics_adwords"
                  id="analytics-adwords"
                  label="Google AdWords Code"
                  placeholder='AW-XXXXXXXXX'
                  value={this.state.analytics.adwords}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={6} md={6}>
                <FormGroupHelper
                  type="text"
                  name="analytics_adwordsconversion"
                  id="analytics-adwordsconversion"
                  label="Google AdWords Conversion Code"
                  // placeholder='AW-XXXXXXXXX'
                  value={this.state.analytics.adwordsconversion}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              {/* <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="settings_background"
                  id="settings-background"
                  label="Background"
                  value={this.state.settings.background}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="settings_color"
                  id="settings-color"
                  label="Color"
                  value={this.state.settings.color}
                  onChange={this.handleChange.bind(this)}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <FormGroupHelper
                  type="text"
                  name="settings_font"
                  id="settings-font"
                  label="Font"
                  value={this.state.settings.font}
                  onChange={this.handleChange.bind(this)}
                />
              </Col> */}
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <FormGroup className="clearfix">
              <Button type="submit" role="button" bsStyle="snkrly" className="pull-right">Update Settings</Button>
            </FormGroup>
          </Col>
          <Clearfix />
        </Form>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    loading: state.settings.loading,
    influencer: state.auth.user.influencer,
    alerts: state.alerts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (data, token) => {
      dispatch(updateProfile(data, token));
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));