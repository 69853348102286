'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setMetatags } from '../../actions/settings';
import { page_metas, getReCaptchaSiteKey, loadReCaptcha } from '../Helper';
import { Row,Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Login from './Login';
import Signup from './Signup';

class LoginSignup extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.login;
    this.state = {
      activeTab:'login',
      // pageTitle: metatags.title,
      // email: '',
      gtoken: '',
      siteKey: getReCaptchaSiteKey(),
      // action: "forgot"
    };
    this.props.setMetatags(metatags);
  }
  componentDidMount() {
    loadReCaptcha("6LeseP4UAAAAAGQ1lrr39C37ZXgTWqBfHUpMBDas");
  }
  componentDidUpdate() {
    if (this.props.isAuthenticated) {
      this.props.clearAlerts();
      const route_state = this.context.router.route.location.state;
      const url = (route_state && route_state.referrer) ? this.context.router.route.location.state.referrer : '/';
      this.context.router.history.push(url);
    }
  }
  // componentWillUnmount() {
  //   this.props.clearAlerts();
  // }

  verifyCallback(recaptchaToken) {
    this.setState({ gtoken: recaptchaToken });
  }

  updateToken() {
    this.recaptcha.execute();
  }

  // handleChange(event) {
  //   this.setState({ [event.target.name]: event.target.value });
  // }

  // handleSubmit(event) {
  //   event.preventDefault();
  //   this.props.clearAlerts();
  //   this.updateToken();
  //   this.props.forgotPassword(this.state.email, this.state.gtoken, this.props.influencer);

  // }

  render() {
    return (
      <Row id='login-signup-continer'>
          {/* <Col xs={12} sm={6} md={6}> */}
            <Tabs
              id="login-signup-tabs"
              activeKey={this.state.activeTab}
              onSelect={(k) => this.setState({activeTab :k })}
            >
              <Tab eventKey="login" title="Login">
                <Login siteKey={this.state.siteKey} />
              </Tab>
              <Tab eventKey="signup" title="SignUp">
                <Signup siteKey={this.state.siteKey} />
              </Tab>
            </Tabs>
          {/* </Col> */}
      </Row>
    );
  }
}
LoginSignup.contextTypes = {
  router: PropTypes.object.isRequired
}
LoginSignup.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts,
    influencer: state.influencer,
    isAuthenticated: state.auth.token ? true : false
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginSignup));
