import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import qs from 'query-string';
import {  Row, Col, PageHeader, Clearfix } from 'react-bootstrap';
import Alerts from '../Alerts';
import ReleaseItem from './Item';
import { list, categoryTagSearch, SearchApi } from '../../actions/release';
import { record404 } from '../../actions/settings';
import FilterSidebar from './FilterSidebar'
import ReleaseHeader from './ReleaseHeader'
import { decorateMenuButton, LoadMorePager, detectCategoryUrl, HomeNavigation, SearchResult } from '../Helper';
import PropTypes from 'prop-types'
import Breadcrumb from '../Breadcrumb';
import { KicksonfireTagBrandBlock } from './Block';

class CategoryTag extends Component {
    constructor(props) {
        super(props);
        const location = props.location;
        const api = props.hasOwnProperty("api") && props.api != undefined ? props.api : 'shop';
        const slug = props.hasOwnProperty("slug") && props.slug != undefined ? props.slug : '';
        let releaseType = props.hasOwnProperty("releaseType") && props.releaseType !== undefined ? props.releaseType : (slug == 'sneaker'?'upcoming':'popular');

        let params = Object.assign({}, location.search ? qs.parse(location.search.replace('?', '')) : {});
        let page = params.hasOwnProperty("page") ? parseInt(params.page) : 1;
        let keyword = props.hasOwnProperty("keyword") && props.keyword != undefined ? props.keyword.trim() : '';;// params.hasOwnProperty("q") ? params.q.trim() : "";
        let sortby = params.hasOwnProperty("sort") ? params.sort : "popular";
        let sortbyList = this.generateListSortBy(keyword, api);
        let selectedBrands = params.hasOwnProperty("brand") && params.brand.trim().length > 0 ? params.brand.toLowerCase().split(",") : [];
        let selectedGenders = params.hasOwnProperty("gender") && params.gender.trim().length > 0 ? params.gender.toLowerCase().split(",") : [];
        let selectedSizes = params.hasOwnProperty("size") && params.size.trim().length > 0 ? params.size.split(",").map(value => parseFloat(value)) : [];
        let selectedShipping = params.hasOwnProperty("shipping") && params.shipping.trim() ? params.shipping.toLowerCase().trim() : "";
        if(slug !== 'sneaker' && sortby !== releaseType){
            releaseType = sortby;
        }
        this.state = {
            slug: slug,
            releaseType: releaseType,
            page: page > 1 ? page : 1,
            perPage: 24,
            keyword: keyword,
            brands: selectedBrands,
            genders: selectedGenders,
            sizes: selectedSizes,
            shipping: selectedShipping,
            sortby: sortby,
            columnFilterShow: false,
            sortbyList: sortbyList,
        };
    }

    componentDidMount() {
        if(!this.props.isReleaseDate){
            let slug = this.props.slug;
            switch(this.props.slug){
                case 'air-jordans':
                    slug = 'air-jordan';
                case 'nike':
                case 'yeezy':
                case 'air-jordan':
                case 'sneaker':    
                    window.location.href = '/' + slug + '-release-dates';
                    break;            
            }
        }
        if(!this.props.isServer){
            this.handleFetch();
        }else{
            this.props.clearServerLoad();
        }
    }

    componentWillUnmount() {
        this.props.clearAlerts();
        this.props.clearReleases();
    }

    componentDidUpdate(prevProps, prevState) {
        let cloneCurState = { ...this.state }
        let clonePreState = { ...prevState }

        cloneCurState.columnFilterShow = false;
        clonePreState.columnFilterShow = false;
        if (JSON.stringify(cloneCurState).toLowerCase().trim() != JSON.stringify(clonePreState).toLowerCase().trim()) {
            this.handlePageChange();
            this.handleFetch();
            
        }else if (JSON.stringify({pathname: prevProps.location.pathname, search: prevProps.location.search}).toLowerCase().trim() !== JSON.stringify({pathname: this.props.location.pathname, search: this.props.location.search}).toLowerCase().trim()) {
            this.check_for_state_change(this.props);
        }
        if(this.props.redirect !== undefined && this.props.redirect.hasOwnProperty('path') && this.props.redirect.path.length>0){
            const redirect =  this.props.redirect;
            window.location.href = redirect.path+(redirect.searchString.length>0?'?'+redirect.searchString:'');
        }else if(this.props.redirect.hasOwnProperty('statusCode') && this.props.redirect.statusCode == 404){
            const search = this.props.match.params['slug'].trim().split('-');
            // this.props.record404(this.props.location.pathname, this.props.influencer, this.props.token);
            const keyword = search.join('+');
            window.location.href = '/error/404?keyword='+keyword+'&url='+this.props.location.pathname;
        }
    }

    isActiveTab(sort){
        return this.state.sortby === sort;
    }

    check_for_state_change(props) {
        const releaseTypes = ["popular","upcoming", "past"];
        const location = props.location;
        const slug = props.match.params['slug'].toLowerCase();
        
        const query = Object.assign({}, qs.parse(location.search.replace('?', '')));
        const page = query.hasOwnProperty("page") ? parseInt(query.page) : 1;
        const brands = query.hasOwnProperty("brand") ? query.brand.trim().toLowerCase().split(',') : [];
        const genders = query.hasOwnProperty("gender") ? query.gender.trim().toLowerCase().split(',') : [];
        const sizes = query.hasOwnProperty("size") ? query.size.trim().toLowerCase().split(',').map(value => parseFloat(value)) : [];
        const shipping = query.hasOwnProperty("shipping") ? query.shipping.toLowerCase().trim() : "";

        let sortby = query.hasOwnProperty("sort") ? query.sort.toLowerCase().trim() : "popular";

        let releaseType = props.match.params['eventType'];
        if(releaseType === undefined || !releaseTypes.includes(releaseType.toLowerCase())){
            releaseType = 'popular';
            if(props.slug == 'sneaker'){
                releaseType = "upcoming";
            }else if(sortby !== releaseType){
                releaseType = sortby;
            }
        }else {
            releaseType = releaseType.toLowerCase();
        }
        
        let keyword = '';
        if(query.hasOwnProperty('q') && query.q.length>0){
            keyword =  query.q.toLowerCase().trim()
        }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
            keyword =  query.keyword.toLowerCase().trim()
        }

        let newState = {};
        if (keyword.toLowerCase().trim() != this.state.keyword) {
            newState.keyword = keyword.toLowerCase().trim();
            newState.page = 1;
        }

        if (JSON.stringify(brands).toLowerCase().trim() != JSON.stringify(this.state.brands).toLowerCase().trim()) {
            newState.brands = brands;
        }
        if (JSON.stringify(genders).toLowerCase().trim() != JSON.stringify(this.state.genders).toLowerCase().trim()) {
            newState.genders = genders;
            newState.page = 1;
        }
        if (JSON.stringify(sizes).toLowerCase().trim() != JSON.stringify(this.state.sizes).toLowerCase().trim()) {
            newState.sizes = sizes;
            newState.page = 1;
        }
        if (shipping.toLowerCase().trim() != this.state.shipping.toLowerCase().trim()) {
            newState.shipping = shipping;
            newState.page = 1;
        }
        if (sortby.toLowerCase().trim() != this.state.sortby.toLowerCase().trim()) {
            newState.sortby = sortby;
            newState.page = 1;
        }
        if (parseInt(page) != parseInt(this.state.page)) {
            newState.page = page;
        }

        if(releaseType !== this.state.releaseType){
            newState.releaseType = releaseType;
        }
        if(slug !== this.state.slug){
            newState.slug = slug;
        }
        
        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    generateListSortBy(key, api) {
        let sortItems = [];
        return sortItems;
        switch (api) {
            case 'under_retail':
                sortItems = [
                    { value: 'popular', label: 'Popular' },
                    { value: 'discount-desc', label: 'Highest % Off ' },
                    { value: 'price-asc', label: 'Price (Low-High)' },
                    { value: 'price-desc', label: 'Price (High-Low)' },
                ];
                break;
            default:
                if (key == "") {
                    sortItems = [
                        { value: 'popular', label: 'Popular' },
                        { value: 'past', label: 'Past' },
                        { value: 'upcoming', label: 'Upcoming' },
                        { value: 'price-asc', label: 'Price (Low-High)' },
                        { value: 'price-desc', label: 'Price (High-Low)' },
                    ];
                } else {
                    sortItems = [
                        { value: 'relevant', label: 'Relevant' },
                        { value: 'popular', label: 'Popular' },
                        { value: 'past', label: 'Past' },
                        { value: 'upcoming', label: 'Upcoming' },
                        { value: 'price-asc', label: 'Price (Low-High)' },
                        { value: 'price-desc', label: 'Price (High-Low)' },
                    ];
                }
                break;
        }
        return sortItems;
    }

    handlePageChange() {
        let params = {};
        if (this.state.keyword.length > 0) {
            params.q = this.state.keyword.trim();
        }
        if (this.state.brands.length > 0) {
            params.brand = this.state.brands.join(",");
        }

        if (this.state.genders.length > 0) {
            params.gender = this.state.genders.join(",");
        }

        if (this.state.sizes.length > 0) {
            params.size = this.state.sizes.join(",");
        }

        if (this.state.shipping.length > 0) {
            params.shipping = this.state.shipping;
        }

        if (this.state.sortby.length > 0 && this.state.sortby !== "popular") {
            params.sort = this.state.sortby;
        }
        if (this.state.page > 1) {
            params.page = parseInt(this.state.page);
        }

        const query = Object.assign({}, params);
        const searchString = qs.stringify(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
    }

    handleFetch() {
        let brandIds = [];
        if(this.state.brands.length > 0){
            for(let brandName of this.state.brands){
                for(let category of this.props.categories){
                    if(category.name.toLowerCase() == brandName.toLowerCase()){
                        brandIds.push(parseInt(category.id));
                    }
                }
            }
        }        
        let body = {
            page: this.state.page,
            perpage: this.state.perPage,
            api: this.props.api,
            keyword: this.state.keyword,
            sortby: this.state.sortby,
            releaseType: this.state.releaseType,
            slug: this.props.slug,
            filter: {
                country: this.props.country,
                brands: brandIds,
                genders: this.state.genders,
                sizes: this.state.sizes,
                shipping: this.state.shipping
            }
        };
        // if(this.state.slug === 'sneaker'){
        //     body.releaseType = this.state.releaseType;
        // }
        this.props.defaultReleases(body, true, this.props.influencer);
    }

    toggleFilter() {
        this.setState({
        }, () => {
            if (this.state.columnFilterShow) {
                this.closeFilter()
            } else {
                this.openFilter();
            }
        })
    }

    closeFilter() {
        this.setState({
            columnFilterShow: false,
        });
        document.getElementsByTagName('body')[0].classList.remove('filter-open');
    }

    openFilter() {
        this.setState({
            columnFilterShow: true
        });
        document.getElementsByTagName('body')[0].classList.add('filter-open');
    }

    setSortby(value) {
        this.setState({
            sortby: value,
            page: 1,
            stateChange: "sortby"
        });
    }

    setBrands(value) {
        this.setState({
            brands: value,
            page: 1,
            stateChange: "brands"
        });
    }

    setGenders(value) {
        this.setState({
            genders: value,
            page: 1,
            stateChange: "genders"
        });
    }

    setSizes(value) {
        this.setState({
            sizes: value,
            page: 1,
            stateChange: "sizes"
        });
    }

    setShipping(value) {
        this.setState({
            shipping: value,
            page: 1,
            stateChange: "shipping"
        });
    }

    setPage(page = 1) {
        this.setState({
            page: page
        });
    }

    clearFilter() {
        this.setState({
            page: 1,
            brands: [],
            genders: [],
            sizes: [],
            shipping: "",
            stateChange: "clearFilter",
        });
        let query = Object.assign({}, qs.parse(this.props.location.search.replace('?', '')));
        if (query.hasOwnProperty('page')) {
            delete query.page;
        }
        if (query.hasOwnProperty('brand')) {
            delete query.brand;
        }

        if (query.hasOwnProperty('gender')) {
            delete query.gender;
        }
        if (query.hasOwnProperty('size')) {
            delete query.size;
        }
        if (query.hasOwnProperty('shipping')) {
            delete query.shipping;
        }
        const searchString = qs.stringify(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: searchString,
        });
    }

    localNavigation(){
        if(this.props.brandOrTag?.hasOwnProperty('slug')){
            if(this.props.brandOrTag.slug == 'sneaker'){
                return HomeNavigation();
            }

            let navLink = '/';
            let tabsCount = 'two';
            let tabItems = [];
            if(this.props.api === 'tag'){
                navLink += this.props.api + '/'+this.props.brandOrTag.slug;
                tabItems.push({
                    link: navLink, 
                    label: 'Popular', 
                    key: 'popular'
                });
                if(this.props.brandOrTag.hasUpcoming){
                    tabsCount = 'three';
                    tabItems.push({
                        link: navLink + "?sort=upcoming", 
                        label: 'Upcoming', 
                        key: 'upcoming',
                    });
                }              
            }else{
                if(this.props.isReleaseDate){
                    navLink += this.props.brandOrTag.slug+'-release-dates';
                }else{
                    navLink += this.props.api+'/'+this.props.brandOrTag.slug
                }
                if(this.props.brandOrTag.slug !=='sneaker'){
                    tabItems.push({
                        link: navLink, 
                        label: 'Popular', 
                        key: 'popular'
                    });
                    if(this.props.brandOrTag.hasUpcoming){
                        tabsCount = 'three';
                        tabItems.push({
                            link: navLink + "?sort=upcoming", 
                            label: 'Upcoming', 
                            key: 'upcoming',
                        });
                    }                    
                }else{
                    tabItems.push({
                        link: navLink, 
                        label: 'Upcoming', 
                        key: 'upcoming',
                    });                
    
                }
            }

            tabItems.push({
                link: navLink + "?sort=past", 
                label: 'Past', 
                key: 'past',
            });
            const tabsMenu = tabItems.map((item, index) => {
                const stateSort = this.state.sortby;
                switch(item.key){
                    case 'past':
                    case 'upcoming':
                    case 'popular':    
                        item.isActive = (match, location) => {
                            let params = Object.assign({}, location.search ? qs.parse(location.search.replace('?', '')) : {});
                            const sortby = params.hasOwnProperty("sort") && params.sort.length>0 ? params.sort.toLowerCase() : "popular";
                            return sortby === item.key
                        }
                        break;
                }
                return decorateMenuButton(item.link, item.label, item.key, 'btn-block', item)
            });
            return (
                <Row>
                    <Col lg={12}>
                        <div className="kf-nav-wrapper">
                            <ul className={"nav nav-pills "+tabsCount+"-tabs"}>{tabsMenu}</ul>
                        </div>
                    </Col>
                </Row>
            );
        }
        return '';
    }

    loadChildTags(){
        let tagsList = {childTags:[], discoverTags: []}
        if(this.props.brandOrTag?.hasOwnProperty('childTags') && this.props.brandOrTag.id > 0){
            if(false && this.props.brandOrTag.childTags.length == 0 && this.props.api == 'category'){
                this.setState({
                    childTags:  [<></>]
                });
                let body = {
                    keyword: this.props.brandOrTag.name,
                    perpage: 5,
                    exclude_fields:["updated_on", "created_on", "brand","product_prices", "tags", "style_code", "event_date", "id", "prices"],
                    sort: {
                        by: "upcoming",
                        order: "asc"
                    }
                }
                SearchApi(body,
                    (result) => {
                        if(result.data.length>0){
                            const childTags = result.data.map((item, index) => {
                                const key = index + 1;
                                return <li key={index} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><Link itemProp="item" className="btn btn-snkrly btn-snkly-small" to={item.slug}><span itemProp="name">{item.title}</span><meta itemProp="position" content={key} /></Link></li>
                                // return decorateMenuButton("/" + item.slug, item.title, index, 'btn-snkly-small')
                            });
                            this.setState({
                                childTags:  childTags                  
                            });
                        }
                        return '';
                    },
                    (err) => {
                    });
            }else{
                if(this.props.brandOrTag?.childTags.length > 0){
                    const navLink = '/tag/';
                    const childTags = this.props.brandOrTag.childTags.map((item, index) => {
                        const url = navLink + item.slug;
                        const title = item.name;
                        const key = index +1;
                        return <li key={index}><Link itemProp="item" className="btn btn-snkrly btn-snkly-small" to={url}><span>{title}</span></Link></li>
                    });
                    if(this.props.brandOrTag?.tagBrand?.id){
                        const url = detectCategoryUrl(this.props.brandOrTag.tagBrand.slug);
                        const title = this.props.brandOrTag.tagBrand.name;
                        childTags.unshift(<li key={ 'brand'}><Link className="btn btn-snkrly btn-snkly-small" to={url}><span>{title}</span></Link></li>);
                    }
                    tagsList = Object.assign(tagsList, {
                        childTags:  childTags,
                    });
                } else if(this.props.brandOrTag.hasOwnProperty('discoverTags') && this.props.brandOrTag.discoverTags.length > 0){
                    const navLink = '/tag/';
                    let isBrandAdded = false;
                    const discoverTags = this.props.brandOrTag.discoverTags.map((item, index) => {
                        let url = navLink + item.slug;
                        let title = item.name;
                        let key = index +1;
                        if(this.props.brandOrTag?.tagBrand?.id && item.slug == this.props.brandOrTag.tagBrand.slug){
                            url = detectCategoryUrl(this.props.brandOrTag.tagBrand.slug);
                            title = this.props.brandOrTag.tagBrand.name;
                            isBrandAdded = true;
                        }
                        return <li key={index}><Link className="btn btn-snkrly btn-snkly-small" to={url}><span>{title}</span></Link></li>
                    });
                    if(!isBrandAdded){
                        if(this.props.brandOrTag?.tagBrand?.id){
                            const url = detectCategoryUrl(this.props.brandOrTag.tagBrand.slug);
                            const title = this.props.brandOrTag.tagBrand.name;
                            discoverTags.unshift(<li key={ 'brand'}><Link className="btn btn-snkrly btn-snkly-small" to={url}><span>{title}</span></Link></li>);
                        }  
                    }
                    tagsList = Object.assign(tagsList, {
                        discoverTags:  discoverTags                  
                    });                    
                }
            }
        }else if(this.props.brandOrTag?.slug ==='sneaker'){
            const childTags = this.props.categories.map((item, index) => {
                const url = detectCategoryUrl(item.slug);
                const title = item.name;
                const key = index + 1;
                return <li key={index} ><Link className="btn btn-snkrly btn-snkly-small" to={url}><span>{title}</span></Link></li>
            });
            tagsList = Object.assign(tagsList, {
                childTags:  childTags,
            });              
            
        }
        return tagsList;
    }


    render() {
        const country = this.props.country;
        const releases = this.props.items.length > 0 ? this.props.items.map((release, index) => {
            return (
                <ReleaseItem item={release} key={index} country={country} />
            );
        }) : this.props.noitems ? (
            <Col lg={12} className="noitems">No items found.</Col>
        ) : '';
        const makehidden = 'page-title';
        const description = this.props.brandOrTag?.description?this.props.brandOrTag.description:'';
        let breadcrumb = [];
        let tagBrandSection = <></>;
        const { childTags, discoverTags } = this.loadChildTags();
        if(this.props.brandOrTag?.name?.length>0){
            const navLink = '/tag/';
            if(this.props.api == 'category' || this.props.brandOrTag.slug ==='sneaker'){
                breadcrumb.push({
                    title: this.props.brandOrTag.name,
                    url: detectCategoryUrl(this.props.brandOrTag.slug)
                });
            }else{
                if(this.props.brandOrTag?.tagBrand?.id){
                    breadcrumb.push({
                        title: this.props.brandOrTag.tagBrand.name,
                        url: detectCategoryUrl(this.props.brandOrTag.tagBrand.slug)
                    });
                    tagBrandSection = <KicksonfireTagBrandBlock brand={this.props.brandOrTag.tagBrand} />
                }
                if(this.props.brandOrTag.hasOwnProperty('discoverTags') && this.props.brandOrTag.discoverTags.length > 0){
                    let lessTagName = this.props.brandOrTag.name.split(' ');
                    let lessTagNameCorrect = this.props.brandOrTag.name;
                    if(lessTagName.length > 2 ){
                        lessTagName.pop();
                        lessTagNameCorrect = lessTagName.join(' ');
                        const discoverTags = this.props.brandOrTag.discoverTags.forEach((item, index) => {
                            if(index < 3){
                                let add = true;
                                if(item.name.length <= lessTagNameCorrect.length){
                                    breadcrumb.forEach(element =>{
                                        if(element.title.toLowerCase() == item.name.toLowerCase()){
                                            add = false;
                                        }
                                    });
                                    if(add){
                                        breadcrumb.push({
                                            title: item.name,
                                            url: navLink + item.slug
                                        });
                                    }
                                }
                            }
                        });
                    }
                } 
                breadcrumb.push({
                    title: this.props.brandOrTag.name,
                    url: navLink + this.props.brandOrTag.slug
                });
            }
        }
        return (
            <div id="page-releases" className={this.props.api}>
                <Breadcrumb items={breadcrumb} />
                <Alerts alerts={this.props.alerts} />
                {this.localNavigation()}
                <PageHeader bsClass="md"  className={makehidden}>{this.props?.brandOrTag?.name}</PageHeader>
                <Row>
                    <Col lg={12}>
                        {description.length>0?<div className='description' dangerouslySetInnerHTML={{__html: '<p>'+ description.replace('\n\r', '</p><p>')+'</p>'}} />:''}
                        {childTags.length>0?<Col xs={12} sm={12} md={12} className="clear-paddings section-related-releases type-tags clear-margins">
                            <div className="kf-nav-wrapper tags">
                                <ol className="nav nav-pills">{childTags}</ol>
                            </div>
                        </Col>:''}
                        {discoverTags.length>0?<Col xs={12} sm={12} md={12} className="clear-paddings section-related-releases type-tags clear-margins">
                            <div className="kf-nav-wrapper tags">
                                {/* <div className='discover-title'>Discover {this.props?.brandOrTag?.discoverTagName}:</div> */}
                                <ol className="nav nav-pills">{discoverTags}</ol>
                            </div>
                        </Col>:''}                        
                    </Col>
                </Row>
                {SearchResult({keyword: this.state.keyword, total: this.state.total })}
                <ReleaseHeader toggleFilterAction={() => this.toggleFilter()} setSortby={(value) => this.setSortby(value)} sortby={this.state.sortby} listSortby={this.state.sortbyList} isFilterShow={this.state.columnFilterShow} />
                <Row id="row-container" className={this.state.columnFilterShow == true ? "open-filters" : ""}>
                    <div id="column-filter" className="col-lg-3">
                        <FilterSidebar
                            toggleSidebar={() => this.toggleFilter()}
                            setBrands={(value) => this.setBrands(value)}
                            setGenders={(value) => this.setGenders(value)}
                            setSizes={(value) => this.setSizes(value)}
                            setShipping={(value) => this.setShipping(value)}
                            clearFilter={() => this.clearFilter()}
                            categories={this.props.categories}
                            brands={this.state.brands}
                            genders={this.state.genders}
                            sizes={this.state.sizes}
                            shipping={this.state.shipping}
                            api={this.props.api} 
                            showBrand={this.props.slug == 'sneaker'? true : false} />
                    </div>
                    <div id="release-filter" className="col-lg-12">
                        <div className="releases-container">
                            {releases}
                            <Clearfix />
                        </div>
                        <LoadMorePager pages={this.props.pageCount} current={this.state.page} setPage={(value)=>this.setPage(value)}  location={this.props.location} />
                        {tagBrandSection}
                    </div>
                </Row>
                <div className="btn-filter-wrapper-float">
                    <button  id="search-filter" name="searc_filter" aria-label="Search Filters" className="btn btn-snkrly-dark btn-block" style={{ display: this.state.columnFilterShow ? "none" : "" }} onClick={() => this.toggleFilter()}>
                        <i className="fa fa-sliders-h fa-rotate-90" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        );
    }
}

CategoryTag.contextTypes = {
    router: PropTypes.object.isRequired
}

CategoryTag.propTypes = {
    location: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    let props = {
        alerts: state.alerts,
        influencer: state.influencer,
        items: state.releases.items,
        pageCount: state.releases.pageCount,
        total: state.releases.total,
        page: state.releases.page,
        brandOrTag: state.releases.brandOrTag,
        isServer: state.settings.isServer,
        metatags: state.metatags,
        noitems: state.settings.noitems,
        routing: state.routing,
        country: state.settings.location ? state.settings.location.snkrly_country_code : '',
        redirect: state.settings.redirect,
        categories: state.settings.categories
    };
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        defaultReleases: (request, showloader, influencer) => {
            switch (request.api) {
                case "category":
                case "tag":
                    dispatch(categoryTagSearch(request, showloader, influencer));
                    break;
                default:
                    dispatch(list(request, showloader, influencer));
                    break;
            }
        },
        record404: (url, influencer = {}, token = null) => {
            dispatch(record404(url, influencer, token));
        },        
        clearReleases: () => {
            dispatch({ type: 'CLEAR_RELEASES' });
        },
        clearServerLoad: () => {
            dispatch({ type: 'SERVER_LOAD_END' });
        },
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}

const ReleaseReleaseDates = (props) => {
    const slug = props.match.params['slug'];
    const eventType = props.match.params['eventType'];
    const query = Object.assign({}, qs.parse(props.location.search.replace('?', '')));
    let keyword = '';
    if(query.hasOwnProperty('q') && query.q.length>0){
        keyword =  query.q.toLowerCase().trim()
    }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
        keyword =  query.keyword.toLowerCase().trim()
    }     
    return (
        <Releases api="category" isReleaseDate={true} slug={slug} releaseType={eventType} keyword={keyword} {...props} />
    )
}

const ReleaseCategory = (props) => {
    const slug = props.match.params['slug'];
    const eventType = props.match.params['eventType'];
    const query = Object.assign({}, qs.parse(props.location.search.replace('?', '')));
    let keyword = '';
    if(query.hasOwnProperty('q') && query.q.length>0){
        keyword =  query.q.toLowerCase().trim()
    }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
        keyword =  query.keyword.toLowerCase().trim()
    }     
    return (
        <Releases api="category" isReleaseDate={false} slug={slug} releaseType={eventType} keyword={keyword} {...props} />
    )
}

const ReleaseTag = (props) => {
    const slug = props.match.params['slug'];
    const eventType = props.match.params['eventType'];
    const query = Object.assign({}, qs.parse(props.location.search.replace('?', '')));
    let keyword = '';
    if(query.hasOwnProperty('q') && query.q.length>0){
        keyword =  query.q.toLowerCase().trim()
    }else if(query.hasOwnProperty('keyword')  && query.keyword.length>0){
        keyword =  query.keyword.toLowerCase().trim()
    }     
    return (
        <Releases api="tag"  isReleaseDate={false} releaseType={eventType} slug={slug} keyword={keyword} {...props} />
    )
}

// export const Releases = withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryTag));
// export const Category = withRouter(ReleaseCategory);
// export const Tag = withRouter(ReleaseTag);
// export const ReleaseDates = withRouter(ReleaseReleaseDates);

export const Releases = withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryTag));
export const Category = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseCategory));
export const Tag = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseTag));
export const ReleaseDates = withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseReleaseDates));
