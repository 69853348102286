'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { page_metas, StaticPage } from '../Helper';
import { setMetatags } from '../../actions/settings';
import capitalize from 'capitalize';

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = { pageTitle: "PRIVACY POLICY" };
    const metatags = page_metas.privacy;
    this.props.setMetatags(metatags);
    this.getConntent = this.getConntent.bind(this);
  }
  getConntent() {
    const sitename = capitalize.words(this.props.influencer.id ? (this.props.influencer.sitename != '' ? this.props.influencer.sitename : this.props.influencer.subdomain) : 'SNKR.LY');
    const link = this.props.influencer.id ? '/' : '//snkr.ly';
    const site_url = <Link to={link} target="_blank">{sitename}</Link>;
    const content =
      <div>
        <p>Last updated February 16, 2018</p>
        <h3></h3>
        <p><b>{sitename}</b> (“we” or “us” or “our”) respects the privacy of our users (“user” or “you”). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <u><b>{site_url}</b></u> and our mobile application including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”). Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE SITE.</p>
        <p>We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Revised” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification. You are encouraged to periodically review this Privacy Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Site after the date such revised Privacy Policy is posted.</p>

        <h3>COLLECTION OF YOUR INFORMATION</h3>
        <p>We may collect information about you in a variety of ways. The information we may collect on the Site includes:  </p>

        <h4>Personal Data</h4>
        <p>Personally identifiable information, such as your name, shipping address, email address, and telephone number, and demographic information, such as your age, gender, hometown, and interests, that you voluntarily give to us when you register with the Site or our mobile application, or  when you choose to participate in various activities related to the Site and our mobile application, such as online chat and message boards. You are under no obligation to provide us with personal information of any kind, however your refusal to do so may prevent you from using certain features of the Site and our mobile application.</p>

        <h4>Derivative Data</h4>
        <p>Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site. If you are using our mobile application, this information may also include your device name and type, your operating system, your phone number, your country, your likes and replies to a post, and other interactions with the application and other users via server log files, as well as any other information you choose to provide.</p>

        <h4>Financial Data</h4>
        <p>Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site or our mobile application. We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, PayPal, and you are encourage review their privacy policy and contact them directly for responses to your questions.</p>

        <h4>Mobile Device Data</h4>
        <p>Device information, such as your mobile device ID, model, and manufacturer, and information about the location of your device, if you access the Site from a mobile device.</p>

        <h4>Mobile Application Information</h4>
        <p>If you connect using our mobile application: </p>
        <ul style={{ listStyleType: "disc" }} >
          <li><i>Geo-Location Information.</i> We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our mobile application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings. </li>
          <li><i>Mobile Device Data.</i> We may collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and IP address. </li>
          <li><i>Push Notifications.</i> We may request to send you push notifications regarding your account or our Services. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</li>
        </ul>



        <h3>USE OF YOUR INFORMATION</h3>
        <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site or our mobile application to: </p>
        <ul style={{ listStyleType: "disc" }} >
          <li>Assist law enforcement and respond to subpoenas.</li>
          <li>Administer sweepstakes, promotions, and contests.</li>
          <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
          <li>Create and manage your account.</li>
          <li>Deliver targeted advertising, coupons, newsletters, and promotions, and other information regarding our website and mobile application to you.</li>
          <li>Email you regarding your account or order.</li>
          <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Site and our mobile application.</li>
          <li>Generate a personal profile about you to make future visits to the Site and our mobile application more personalized.</li>
          <li>Increase the efficiency and operation of the Site and our mobile application. </li>
          <li>Monitor and analyze usage and trends to improve your experience with the Site and our mobile application.</li>
          <li>Notify you of updates to the Site and our mobile application.</li>
          <li>Offer new products, services, mobile applications, and/or recommendations to you.</li>
          <li>Perform other business activities as needed. </li>
          <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
          <li>Process payments and refunds.</li>
          <li>Request feedback and contact you about your use of the Site and our mobile application.</li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>Respond to product and customer service requests.</li>
          <li>Send you a newsletter.</li>
        </ul>

        <h3>DISCLOSURE OF YOUR INFORMATION</h3>
        <p>You may be required to register with the Site.You agree to keep your password confidential and will be responsible for all use of your account and password.We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>
        <h4>By Law or to Protect Rights</h4>
        <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.  This includes exchanging information with other entities for fraud protection and credit risk reduction.</p>
        <h4>Third-Party Service Providers</h4>
        <p>We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance. </p>

        <h4>Marketing Communications</h4>
        <p>With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law. </p>

        <h4>Affiliates</h4>
        <p>We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</p>

        <h4>Business Partners</h4>
        <p>We may share your information with our business partners to offer you certain products, services or promotions. </p>

        <h4>Other Third Parties</h4>
        <p>We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.</p>
        <h4>Sale or Bankruptcy</h4>
        <p>If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity.  If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.</p>
        <p>We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly. </p>

        <h3>TRACKING TECHNOLOGIES</h3>
        <h4>Cookies and Web Beacons </h4>
        <p>We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site and our mobile application to help customize the Site and our mobile application and improve your experience. For more information on how we use cookies, please refer to our Cookie Policy posted on the Site, which is incorporated into this Privacy Policy. By using the Site, you agree to be bound by our Cookie Policy.</p>
        <h4>Website Analytics</h4>
        <p>We may also partner with selected third-party vendors, such as Google Analytics, to allow tracking technologies and remarketing services on the Site and our mobile application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site and our mobile application, determine the popularity of certain content and better understand online activity. By accessing the Site, our mobile application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, If you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor or <a href="//developers.facebook.com/docs/facebook-login/permissions" target="_blank"><u>Network Advertising Initiative Opt-Out Tool</u></a> or <a href="//www.aboutads.info/choices/" target="_blank"><u>Digital Advertising Alliance Opt-Out Tool</u></a>.</p>
        <p>You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.</p>

        <h3>THIRD-PARTY WEBSITES</h3>
        <p>The Site and our mobile application may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site or our mobile application, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site or our mobile application. </p>

        <h3>SECURITY OF YOUR INFORMATION</h3>
        <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.</p>

        <h3>POLICY FOR CHILDREN</h3>
        <p>We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided below.</p>

        <h3>CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
        <p>Most web browsers and some mobile operating systems and our mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.  No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.</p>

        <h3>OPTIONS REGARDING YOUR INFORMATION</h3>
        <h4>Account Information</h4>
        <p>You may at any time review or change the information in your account or terminate your account by: </p>
        <ul style={{ listStyleType: "disc" }} >
          <li>Logging into user account settings and updating the user account.</li>
          <li>Contacting us using the contact information provided. </li>
        </ul>
        <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>

        <h4>Emails and Communications</h4>
        <p>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</p>
        <ul style={{ listStyleType: "disc" }} >
          <li>Contacting us using the contact information provided.</li>
          <li>Logging into your account settings and updating your preferences.</li>
        </ul>
        <p>If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.</p>

        <h3>CALIFORNIA PRIVACY RIGHTS</h3>
        <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
        <p>If you are under 18 years of age, reside in California, and have a registered account with the Site or our mobile application, you have the right to request removal of unwanted data that you publicly post on the Site or our mobile application. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California.  We will make sure the data is not publicly displayed on the Site or our mobile application, but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
      </div>;
    return content;

  }

  render() {


    return (
      <StaticPage
        title={this.state.pageTitle}
        content={this.getConntent()}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    influencer: state.influencer ? state.influencer : {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Privacy));
