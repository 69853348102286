'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import { Link, withRouter } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { InfluencerHeader } from './Influencer';
import { decorateMenuItem, overflowOnOff, detectCategoryUrl } from './Helper';
import Sidebar from './Sidebar'

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.sidebarOpen !== this.state.sidebarOpen) {
      overflowOnOff(this.state.sidebarOpen);
    }
  }
  handleLogout(event) {
    event.preventDefault();
    this.props.dispatch(logout(this.props.influencer, this.props.token));
    this.props.history.push('/');
  }

  openSideBar() {
    this.setState({ sidebarOpen: true });
    return false;
  }

  closeSideBar() {
    this.setState({ sidebarOpen: false });
    return false;
  }

  render() {
    if (!this.props.influencer || !this.props.influencer.status) {
      return (
        <InfluencerHeader />
      )
    }
    const activeClass = 'active';
    const is_influencer_owner = this.props.user.influencer && this.props.user.influencer._id.toString() === this.props.influencer._id.toString();
    const rightNav = this.props.token ? (
      <li className="dropdown">
        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">My Account<i className="fas fa-chevron-right"></i></a>
        <ul className="dropdown-menu">
          {is_influencer_owner ? decorateMenuItem('/dashboard', 'Dashboard', 'dashboard') : ''}
          {is_influencer_owner ? decorateMenuItem('/settings', 'Settings', 'settings') : ''}
          {decorateMenuItem('/orders', 'My Orders', 'orders')}
          {decorateMenuItem('/account', 'My Account', 'account')}
          <li><a href="#" onClick={this.handleLogout.bind(this)}>Logout</a></li>
        </ul>
      </li>
    ) : (
      <>
        {decorateMenuItem('/login', 'Log in', 'login')}
        {decorateMenuItem('/signup', 'Sign up', 'signup')}
      </>
    );
    let influencer_settings = {
      brand: '',
      slogon: "We\'re changing the way people buy and sell sneakers.",
      logo: '/logos/snkrly.png',
      title: '',
    };
    if (this.props.influencer.status) {
      influencer_settings.brand = this.props.influencer.subdomain;
      if (this.props.influencer.slogon) {
        influencer_settings.slogon = this.props.influencer.slogon;
      }
      if (this.props.influencer.logo != '') {
        influencer_settings.logo = this.props.influencer.influencer_logo;
      }
    }

    let site_slogon = this.props.influencer.status ? this.props.influencer.slogon : 'SNKR.LY';
    const brands = this.props.categories.map((item, index) => {
      const url = detectCategoryUrl(item.slug);
      return decorateMenuItem(url, item.name, index)
    });

    return (
      <Navbar staticTop={true} bsStyle="default" collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand className="order-md-0 mx-auto order-1">
            <Link to="/" className="navbar-brand">
              <img alt={site_slogon} width={80} height={45} src={influencer_settings.logo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle className="order-md-1 order-0" />

          <div className="nav-icon-mobile">
            <span id='search-mobile-icon' onClick={() => this.openSideBar()}> <i className="social-icon fa fa-search"></i></span>
            {/* <a href="#" className="dropdown-toggle"  id='account-mobile-icon' data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fas fa-user"></i></a>
            <ul className="dropdown-menu">
              {rightNav}
            </ul> */}
          </div>
        </Navbar.Header>
        <Navbar.Collapse id="navbar">
          <ul className="nav navbar-nav navbar-right">
            <li className="nav-icon-desktop">
              <span id='search-desctop-icon' onClick={() => this.openSideBar()}> <i className="social-icon fa fa-search"></i></span>
            </li>
            <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Brands<i className="fas fa-chevron-right"></i></a>
              <ul className="dropdown-menu">
                {brands}
              </ul>
            </li>            
            {decorateMenuItem('/sneaker-release-dates', 'Release Dates', 'sneaker')} 
            {decorateMenuItem('/shop', 'Shop', 'shop')}
            {decorateMenuItem('/popular/monthly', 'Top 100', 'popular')} 
            {/* {decorateMenuItem('/under-retail', 'Under Retail')} */}
            {/* <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><span className='visible-xs'>My Account</span><i className="fas fa-user hidden-xs"></i></a>
              <ul className="dropdown-menu"> */}
                {rightNav}
              {/* </ul>
            </li> */}
          </ul>
        </Navbar.Collapse>
        <Sidebar open={this.state.sidebarOpen} closeSideBar={() => this.closeSideBar()} />
      </Navbar>
    );
  }

}

Header.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    influencer: state.influencer,
    loading: state.settings.loading,
    categories: state.settings.categories,
  };
};

export default withRouter(connect(mapStateToProps)(Header));
