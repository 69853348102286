import React, { Component, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux'
import Alerts from '../Alerts';
import { view } from '../../actions/release';
import { record404 } from '../../actions/settings';
import BuyNow from './BuyNow';
import {KicksonfireRelatedBlock, KicksonfireVariationsBlock} from './Block';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types'
import { Row, Col} from 'react-bootstrap';
import { httpCorrectUrl, dateFormat, detectCategoryUrl } from '../Helper';
import ReactPixel from 'react-facebook-pixel';
import Breadcrumb from '../Breadcrumb';
import {JsonLD } from '../JsonSnipped';
import ReadMore from '../ReadMore'; 

const ReleaseGallery = (props) => {
    const imageGalleryRef = useRef(null);
    
    const onClickHandler = () => {
        imageGalleryRef.current.toggleFullScreen();
      };
    return (
        <ImageGallery
            items={props.images}
            slideInterval={1000}
            showPlayButton={false}
            disableThumbnailScroll={false}
            disableArrowKeys={true}
            showBullets={false}
            showIndex={true}
            showThumbnails={false}
            lazyLoad={false}
            showNav={true}
            useBrowserFullscreen={false}
            showFullscreenButton={true}
            thumbnailPosition='bottom'
            useTranslate3D={false}
            ref={imageGalleryRef}
            onClick={onClickHandler} 
            onScreenChange={(fullScreen)=>{
                if(fullScreen){
                    document.getElementsByTagName('body')[0].classList.add('silder-fullscreen')
                }else{
                    document.getElementsByTagName('body')[0].classList.remove('silder-fullscreen')
                }
            }}
        />
    );
}


export const ImagesGallery = ReleaseGallery

const default_product_snippets = {
    "@context": 'https://schema.org/',
    "@type": 'Product',
    "name": '',
    "mpn": '',
    "sku": '',
    "productID": '',
    "image": [],
    "url": "",
    "description": '',
    "offers": {
        "@type": "AggregateOffer",
        "priceCurrency": "USD",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/OutOfStock",
    }
};

const default_offer = {
    "availability": "https://schema.org/InStock",
    "price": 0,
    "priceCurrency": "USD",
//    "mpn": '',
    "sku": '',
    "productID": '',
    "name": '',
//    "itemCondition": "https://schema.org/NewCondition",
//    "url": '',
};

class ReleaseView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {},
            product_snippets: default_product_snippets,
            statusCode : 200,
            readmore: false
            // randomNum:  Math.random()*10+1
        };

        if(!(props.item !== undefined && props.hasOwnProperty('item') && Object.keys(props.item).length)){
            this.props.clearRelease();
            this.props.getRelease(this.props.match.params['slug'], this.props.influencer, this.props.token);
        }else{
            // this.state = Object.assign({},this.state, getDerivedStateFromProps(this.props, this.state));
        }
    }
    componentWillUnmount() {
        this.props.clearRelease();
        this.props.clearAlerts();
    }

    componentDidUpdate(prevProps) {
        const oldSlug = prevProps.match.params['slug'];
        const newSlug = this.props.match.params['slug'];
        if (newSlug === oldSlug) {
            return;
        }
        this.props.clearRelease();
        this.props.getRelease(newSlug, this.props.influencer, this.props.token);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.item !== undefined && Object.keys(props.item).length && (Object.keys(state.item).length == 0 || props.item.id != state.item.id)) {            
            const style_code = props.item.style_code ?props.item?.style_code
                .replace(/[\s\-]/gi, '')
                .toUpperCase()
                .trim():'';
            const url = props.item.hasOwnProperty('url')?props.item.url: window.location.href;
            let contents = [];
            let content_ids = [];
            let gtag_items = [];

            const images = [props.item.master_image, ...props.item.images];
            let product_snippets = Object.assign({}, default_product_snippets, {
                name: props.item.title,
                mpn: style_code,
                sku: 'R-' + props.item.id,
                productID: 'R-' + props.item.id,
                url: url,
                // description: props.item.description.trim().length>0?props.item.description.trim():props.item.title,
                image: images
            });
            let prod_description = '';
            if(props.item.description.trim().length>0){
                prod_description = props.item.description.trim();
            }else{
                prod_description = props.item.title;
                if (props.item.style) {
                    prod_description += props.item.style + '. ';
                }
                if (props.item.style_code) {
                    prod_description += props.item.style_code;
                }                
            }
            product_snippets.description = prod_description;
            product_snippets.additionalProperty = []
            if (props.item.style) {
                let prop_colorway = {};
                product_snippets.color = props.item.style;
                prop_colorway["@type"] = 'PropertyValue';
                prop_colorway.name = 'Colorway';
                prop_colorway.value = props.item.style;
                product_snippets.additionalProperty.push(prop_colorway);
            }
            if(props.item.style_code){
                let prop_style_code = {};
                prop_style_code["@type"] = 'PropertyValue';
                prop_style_code.name = 'Style Code ';
                prop_style_code.value = props.item.style_code;
                product_snippets.additionalProperty.push(prop_style_code);
            }
            if(props.item.price != 'N/A' && props.item.price > 0){
                let prop_retail_price = {};
                prop_retail_price["@type"] = 'PropertyValue';
                prop_retail_price.name = 'Retail Price';
                prop_retail_price.value = '$' + parseInt(props.item.price);
                product_snippets.additionalProperty.push(prop_retail_price);
            }
            if(props.item.event_date != null && props.item.event_date != undefined){
                product_snippets.releaseDate = dateFormat({value: props.item.event_date, format: "MMM DD, YYYY" })
            }

            let pixel_json = {
                content_name: props.item.title,
                currency: 'USD',
                content_type: 'product',
            }
            if (props.item.type[0] && props.item.type[0].brand_name) {
                pixel_json.content_category = props.item.type[0].brand_name;
                product_snippets.brand = {};
                product_snippets.brand["@type"] = "Brand";
                let brand_name = props.item.type[0].brand_name;
                if (props.item.title.match(/fear of god/i)) {
                    brand_name = 'Fear Of God';
                }
                product_snippets.brand.name = brand_name;
            }
            product_snippets.category=[ 
                "Shoes",
                "Sneakers",
                "Athletic",
            ];
            let min_value = 1000000;
            let max_value = 0;
            if (props.sizes.length > 0) {
                product_snippets.offers.availability = "https://schema.org/InStock";
                product_snippets.offers.offerCount = props.sizes.length;

                product_snippets.offers.offers = [];
                props.sizes.forEach((element, i) => {
                    let g = 'M';
                    let title_gender = 'Men';
                    switch (element.gender.toLowerCase()) {
                        case 'youth':
                            g = 'K';
                            title_gender = 'Kids';
                            break;
                        case 'men':
                            g = 'M';
                            title_gender = 'Men';
                            break;
                        case 'women':
                            g = 'F';
                            title_gender = 'Women';
                            break;
                    }
                    let sz = element.size.replace('.', '_');
                    let product_price_convert = element.product_price.replace(/,/, "");
                    let size_price_convert = element.size_price.replace(/,/, "");
                    let id = style_code + '-' + sz;
                    let price = parseFloat(parseFloat(product_price_convert) + parseFloat(size_price_convert));
                    content_ids.push(id);
                    gtag_items.push({
                        'id': id,
                        'list_position': ++i,
                        'google_business_vertical': 'retail'
                    });

                    contents.push({
                        id: id,
                        quantity: 1,
                        item_price: price
                    });
                    if (price < min_value) {
                        min_value = price;
                    }
                    if (price > max_value) {
                        max_value = price;
                    }

                    let format_price = price.toFixed(0);
                    let productID_sku = 'R-' + props.item.id + "-" + g + "-" + sz;//element.google_id ? element.google_id : props.item.id + '-' + sz;
                    let offer = Object.assign({}, default_offer, {
                        price: format_price.toLocaleString('en-US'),
                        sku: productID_sku,
                        productID: productID_sku,
                        name: props.item.title + ' - Size ' + element.size + ' ' + title_gender,
                    });
                    if (element.gtin && element.gtin.length > 0) {
                        let gtin_length = element.gtin.length;
                        if (gtin_length <= 8) {
                            offer.gtin8 = element.gtin.padStart(8, '0');
                        } else if (gtin_length <= 12) {
                            offer.gtin12 = element.gtin.padStart(12, '0');
                        } else if (gtin_length == 13) {
                            offer.gtin13 = element.gtin;
                        } else if (gtin_length == 14) {
                            offer.gtin14 = element.gtin;
                        }
                    }
                    product_snippets.offers.offers.push(offer);
                });
                const format_min_value = min_value.toFixed(0);
                const format_max_value = max_value.toFixed(0);
                product_snippets.offers.lowPrice = format_min_value.toLocaleString('en-US');
                product_snippets.offers.highPrice = format_max_value.toLocaleString('en-US');
            } else {
                min_value = (props.item.price !== 'N/A' && parseInt(props.item.price) !== NaN && typeof parseInt(props.item.price) === 'number') ? props.item.price : 0;
                product_snippets.offers.price = min_value;
                product_snippets.offers["@type"] = "Offer";
                product_snippets.offers.url = url;
            }
            // Shipping 
            product_snippets.offers.shippingDetails = {};
            product_snippets.offers.shippingDetails["@type"] = "OfferShippingDetails";
            product_snippets.offers.shippingDetails.shippingRate = {};
            product_snippets.offers.shippingDetails.shippingRate["@type"] = "MonetaryAmount";
            product_snippets.offers.shippingDetails.shippingRate.value = 0;
            product_snippets.offers.shippingDetails.shippingRate.currency = "USD";
            product_snippets.offers.shippingDetails.shippingDestination = [];
            let shippingDestinationUS = {};
            shippingDestinationUS["@type"] = "DefinedRegion";
            shippingDestinationUS.addressCountry =  "US";
            product_snippets.offers.shippingDetails.shippingDestination.push(shippingDestinationUS);
            // if(country == 'CA'){
            //     let shippingDestinationCA = {};
            //     shippingDestinationCA["@type"] = "DefinedRegion";
            //     shippingDestinationCA.value =  "CA";
            //     product_snippets.offers.shippingDetails.shippingDestination.push(shippingDestinationCA);
            // }
            if (contents.length > 0) {
                pixel_json.contents = contents;
                pixel_json.content_ids = content_ids;
            }
            if(typeof window !== "undefined"){
                if (min_value > 0) {
                    pixel_json.value = min_value;
                    const ga_adwords = props.influencer.analytics.adwords ? props.influencer.analytics.adwords : '';
                    if (ga_adwords) {
                        window.gtag('event', 'view_item', {
                            'send_to': ga_adwords,
                            'value': min_value,
                            'items': gtag_items,
                            'currency': 'USD',
                        });
                    }
                }
                ReactPixel.track('ViewContent', pixel_json);
            }

            let description = '';
            if (props.item.style) {
                description += props.item.style + '. ';
            }
            if (props.item.style_code) {
                description += props.item.style_code;
            }
            const gallery_images = images.length > 0 ? images.map((image, index) => {
                return {
                    original: httpCorrectUrl(image),
                    originalAlt: props.item.title,
                    originalTitle: props.item.title,
                }
            }) : {};
            const item = Object.assign({}, props.item, { desc: description, gallery_images: gallery_images });
            const wants = props.item.wants < 100 ? props.item.wants+100 : props.item.wants;
            product_snippets.aggregateRating = {};
            product_snippets.aggregateRating.ratingValue = Math.round(((5 * wants/100) * 4.8 + ((20-5) * wants/100) * 4.9 + ((80) * wants/100) * 5) / (wants)*100)/100;
            product_snippets.aggregateRating["@type"] = "AggregateRating";
            product_snippets.aggregateRating.reviewCount = wants;
            return {
                item: item,
                product_snippets: product_snippets
            }
        }
        if(props.redirect !== undefined && props.redirect.hasOwnProperty('path') && props.redirect.path.length>0){
            const redirect =  props.redirect;
            window.location.href = redirect.path+(redirect.searchString.length>0?'?'+redirect.searchString:'');
        }else if(props.redirect.hasOwnProperty('statusCode') && props.redirect.statusCode == 404){
            const search = props.match.params['slug'].trim().split('-');
            props.record404("/" + props.match.params['slug'], props.influencer, props.token);
            const keyword = search.join('+');
            window.location.href = '/error/404?keyword='+keyword+'&url='+props.location.pathname;
        }
        return null;
    }

    render() {
        if(this.props.router?.location !== null && this.props.item !== undefined && Object.keys(this.props.item).length){
            // const blocksUrl = "/search?keyword="+this.props.item.title.replace(' ','+');
            const releatedBlocksUrl = "/"+this.props.releatedTag.tag.type+"/"+this.props.releatedTag.tag.slug;
            // const isUpcoming = isPastOrUpcoming(this.props.item.event_date);
            // const message = isUpcoming ? <div id="release-date" className='btn btn-snkrly'><b>Release Date: </b>{ dateFormat({value:this.props.item.event_date, format: "MMM DD, YYYY" })}</div> : <div className="out-of-stock">{"Not in Stock"}</div>;
            
            const wants_count = this.props.item.wants < 100 ? this.props.item.wants+100 :this.props.item.wants;
            const wants = (<div className="likes-wrapper section-block"><i className="likes like-flame-red"><img src="/default/fire.png" width={14} height={14} alt="Likes" title='Likes' className='img-responsive' /></i><span className="td-likes-{this.props.item.id}">{wants_count.toLocaleString()}</span></div>) ;
            let breadcrumb = [];
            breadcrumb.push({
                title: this.props.item?.brand?.brand_name,
                url: detectCategoryUrl(this.props.item?.brand?.slug)
            });
            if(this.props.item.tags.length>0){
                this.props.item.tags.forEach((tag, index)=>{
                    if(!this.props.item?.brand?.hasOwnProperty('slug') || tag.slug !== this.props.item?.brand?.slug){
                        if(index < 3){
                            breadcrumb.push({
                                title: tag.name,
                                url: '/tag/' + tag.slug
                            });
                        }
                    }
                });
            }
            breadcrumb.push({
                title: this.props.item.title,
                url: this.props.item.slug
            });
            // let description =  <ItemDescription description={this.props.item.description} isOpen={this.state.readmore} toggleFilterAction={() => this.toggleFilter()} />;
            return (
                <>
                    <Breadcrumb items={breadcrumb} />
                    <Row id="release-view-wrapper">
                        <JsonLD jsonData={this.state.product_snippets} />
                        {this.props.alerts.length>0?
                            <Col xs={12} sm={12} md={12}>
                                <Alerts alerts={this.props.alerts} />
                            </Col> : ''}
                        <div id="release-content">
                            <Col xs={12} sm={6} md={6}  className='product-wrapper'>
                                <h1>{this.props.item ? this.props.item.title : ''}</h1>
                                {wants}
                                
                                {/* //dangerouslySetInnerHTML={{__html: description}} */}
                                {/* <PageHeader  className='section-block'></PageHeader> */}
                                <BuyNow sizes={this.props.sizes} release={this.props.item} />                            
                                {this.props.item.description.length>0?<div className='description section-block' >{<ReadMore  description={this.props.item.description} />}</div>:<></>} 
                                <div className='section-block'>
                                    <ItemInforation  release={this.props.item} hasSize={this.props.sizes.length > 0} />
                                </div>
                                <div className='section-block'>
                                    <ItemTags tags={this.props.item.tags } brand={this.props.item.brand} />
                                </div>
                                {/* </Row> */}
                            </Col>
                            <Col xs={12} sm={6} md={6} className='release-image-wrapper'>
                                <ImagesGallery images={this.state.item.gallery_images} />
                                { this.props.hasOwnProperty('variations') && this.props.variations?.length>1 ? <KicksonfireVariationsBlock id="variations"  release_id={this.props.item.id} items={this.props.variations} /> : '' }
                            </Col>
                            
                        </div>
                        <Col xs={12} sm={12} md={12} className="release-blocks-wrapper">
                                {/* <KicksonfireBlock id="past" title={"Past"} items={this.props.related.hasOwnProperty('past')?this.props.related.past:[]} country={this.props.country} showAll={true} showAllLink={blocksUrl+"&sort=past"}  /> */}
                                {/* <KicksonfireBlock id="upcoming" title={"Upcoming"} items={this.props.related.hasOwnProperty('upcoming')?this.props.related.upcoming:[]} country={this.props.country}  showAll={true} showAllLink={blocksUrl+"&sort=upcoming"} /> */}
                                { this.props.releatedTag.hasOwnProperty('items') ? <KicksonfireRelatedBlock id="related" title={this.props.releatedTag?.tag.name} items={this.props.releatedTag.items} total={this.props.releatedTag?.total} tag={this.props.releatedTag?.tag} showAll={true} showAllLink={releatedBlocksUrl} /> : '' }
                        </Col>  
                    </Row>
                </>
            )
        }
        return <></>;
    }
}

ReleaseView.contextTypes = {
    router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        influencer: state.influencer,
        user: state.auth.user,
        alerts: state.alerts,
        item: state.release.item.release,
        sizes: state.release.item.sizes,
        related: state.release.item.related,
        variations: state.release.item.variations,
        releatedTag: state.release.item.releatedTag,
        country: state.settings.location ? state.settings.location.snkrly_country_code : '',
        redirect: state.settings.redirect
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRelease: async (slug, influencer = {}, token = null) => {
            await dispatch(view(slug, influencer, token));
        },
        clearRelease: () => {
            dispatch({ type: 'CLEAR_RELEASE' });
        },
        record404: async (url, influencer = {}, token = null) => {
            await dispatch(record404(url, influencer, token));
        },        
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}


const ItemInforation = (props) => {
    let item_information = [];
    item_information.push({
        name: "Name ",
        value: <b>{props.release.title}</b>,
    });
    if(props.release.style.length>0){
        item_information.push({
            name: "Colorway ",
            value: props.release.style,
        });  
    }

    if(props.release.event_date.length >0 ){
        item_information.push({
            name: "Release Date ",
            value: dateFormat({value: props.release.event_date, format: "MMM DD, YYYY" }),
        });  
    }


    if(props.release.style_code){
        item_information.push({
            name: "Style Code ",
            value: props.release.style_code,
        });  
    }
    if(props.release.tags.length>0){
        if(props.release.tags.length>0){
            let tags = props.release.tags.toReversed();
            if (tags && tags.length > 0) {
                const brand = props.release.brand;
                let key = 0;
                let tagItems = [];
                tags.forEach((tag)=>{
                    if((!brand.hasOwnProperty('slug') || tag.slug !== brand.slug) && key < 2){
                        let path = props.hasOwnProperty('path')? props.path : 'tag';
                        let slug = tag.slug;
                        let title = tag.name;
                        let url = "/" + path + '/' + slug;
                        let is_category = false;
                        switch(tag.slug){
                            case "air-jordans":
                                slug = "air-jordan";
                                title = "Air Jordan"
                            case "air-jordan":
                            case "yeezy":
                            case "nike":
                                path = slug + '-release-dates';
                                url = "/" + path;
                                is_category = true;
                                break;
                            case "adidas":
                            case "asics":
                            case "converse":
                            case "jordan-brand":
                            case "new-balance":
                            case "puma":
                            case "reebok":
                            case "supra":
                            case "under-armour":
                            case "vans": 
                                url = "/category/" + slug;
                                is_category = true;
                                break; 
                        }
                        if(!is_category){
                            key++;
                            tagItems.push(<Link key={key} className="model-item" to={url}>{title}</Link>);
                        }
                    }
                });
                if(tagItems.length>0){
                    item_information.push({
                        name: "Model ",
                        value: tagItems.map(t => <>{t}</>).reduce((prev, curr) => [ prev, ', ', curr ])
                    });
                }                
            }

        }
    }


    if(!props.hasSize && parseInt(props.release.price) > 0){
        const retail_price = parseInt(props.release.price).toLocaleString();
        item_information.push({
            name: "Retail Price ",
            value: "$" + retail_price,
        });  
    }
    const information = item_information.map((item, index)=> {
            return (
                <div key={index} className="section-block release-attr-item">
                    <span className="release-attr-title">{item.name}</span>
                    <span className="release-attr-value">{item.value}</span>
                </div>
            )
    });
    // if(props.hasSize){
    //     item_information.push("Brand New");
    // }else{
    //     if(parseInt(props.release.price) > 0){
    //         const item_price = parseInt(props.release.price).toLocaleString();
    //         item_information.push("$" + item_price);
    //     }
    // }

    /*if(props.release.style){
        item_information.push(props.release.style)
    }
    if(props.release.style_code){
        item_information.push(props.release.style_code)
    }
    const info = item_information.join(". ") + ".";
    let wants = '';
    if(props.release.wants >100){
        wants = (<span className="likes-wrapper"><i className="likes like-flame-red"><img src="/default/fire.png" alt="Likes" title='Likes' className='img-responsive' /></i><span className="td-likes-{props.release}">{props.release.wants+". "}</span></span>);
    }*/
    return (
        <div className="release-attibutes">{information}</div>
    )
}

const ItemTags = (props) => {
    const tags = props?.tags;
    const brand = Object.assign({}, props.brand, {name : props.brand?.brand_name});
    let key = 0;
    let releaseTags = [];
    const brandTag = <TagItem key={key} tag={brand} position={key+1} path="category" />;
    releaseTags.push(brandTag);
    if (tags && tags.length > 0) {
        const tagItems = tags.map((tag, index)=> {
            if(!brand.hasOwnProperty('slug') || tag.slug !== brand.slug){
                key++;
                return (
                    <TagItem key={key} position={key+1} tag={tag} />
                )
            }
            return '';
        });
        releaseTags = releaseTags.concat(tagItems)
    }
    if(releaseTags.length > 0){
        return (
            <div className="td-tags td-post-small-box clearfix"><ol className="nav nav-pills" itemScope itemType="https://schema.org/BreadcrumbList">{releaseTags}</ol><div className="clearfix"></div></div>
        )
    }
    return <></>;
}

const TagItem = (props) => {
    const tag = props?.tag ;
    if (tag && tag.hasOwnProperty('slug') > 0) {
        let path = props.hasOwnProperty('path')? props.path : 'tag';
        let slug = tag.slug;
        let title = tag.name;
        let url = "/" + path + '/' + slug;
        
        switch(tag.slug){
            case "air-jordans":
                slug = "air-jordan";
                title = "Air Jordan"
            case "air-jordan":
            case "yeezy":
            case "nike":
                path = slug + '-release-dates';
                url = "/" + path;
                break;
            case "adidas":
            case "asics":
            case "converse":
            case "jordan-brand":
            case "new-balance":
            case "puma":
            case "reebok":
            case "supra":
            case "under-armour":
            case "vans": 
                // path = 'category';
                url = "/category/" + slug;
                break; 
        }
        const position = props.position;
        return (
            <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem"><Link itemProp="item" className="btn btn-snkrly btn-snkly-small" to={url}><span itemProp="name">{title}</span><meta itemProp="position" content={position} /></Link></li>
        )
    }
    return '';
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseView));
