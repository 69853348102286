import React, { Component } from 'react';
import Select from 'react-select';
import { Row, Col, ControlLabel, FormGroup } from 'react-bootstrap';
import { getSelectOption } from '../Helper';

class ReleaseHeader extends Component {
    constructor(props) {
        super(props);
    }

    toggleFilterSidebar() {
        this.props.toggleFilterAction();
    }

    handleSortByChange(event) {
        const value = event.value;
        this.props.setSortby(value);
    }
    render() {
        const options = this.props.listSortby;
        const selected_sort = getSelectOption(this.props.sortby, this.props.listSortby);
        return (
            <Row id="release-header">
                {this.props.isFilterVisible}
                <Col xs={6} sm={8} md={8} id='showFilterContainer'>
                    <div className="show-filter" onClick={() => this.toggleFilterSidebar()} >
                        {this.props.isFilterShow ? "Hide Filters" : "Show Filters"}<span className="icon"><i className="fa fa-angle-right"></i></span>
                    </div>
                </Col>
                {options.length>0 && this.props.isSortVisible ?<Col xs={6} sm={4} md={4} id='sortByContainer'>
                    <FormGroup controlId="sltSortBy" className="row">
                        <Col componentClass={ControlLabel} xs={3} className="clear-padding-right">Sort by:</Col>
                        <Col xs={9}>
                            <Select
                                id='sltSortBy'
                                name="sltSortBy"
                                className="react-select-container"
                                classNamePrefix="react-select"
                                isClearable={false}
                                defaultValue={selected_sort}
                                value={selected_sort}
                                onChange={this.handleSortByChange.bind(this)}
                                options={options}
                            />
                        </Col>
                    </FormGroup>
                </Col>:''}
            </Row>
        )
    }
}
export default ReleaseHeader;
