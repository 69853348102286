'use strict';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom';
import { PageHeader } from 'react-bootstrap';
import PropTypes from 'prop-types'
import ReactPixel from 'react-facebook-pixel';
import { setMetatags } from '../../actions/settings';
import { emptyToCart } from '../../actions/cart';
import { checkout_complate, view } from '../../actions/order';
import Alerts from '../Alerts';
import { page_metas } from '../Helper';

class Complete extends Component {
  constructor(props) {
    super(props);
    const metatags = page_metas.checkout_complete;
    this.state = {
      pageTitle: metatags.title,
      orderId: this.props.match.params['orderId'],
      order: {},
      is_loaded: 0
    };
    this.props.setMetatags(metatags);
  }

  componentWillUnmount() {
    this.props.clearOrder();
  }
  componentDidMount() {
    this.props.clearOrder();
    this.props.viewOrder(this.state.orderId, this.props.influencer, this.props.token, true);
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.loading) {
      if (props.order && props.order.orderId && !state.is_loaded) {
        props.checkout_complate(state.orderId, props.influencer, props.token);
        const order = props.order;
        const item = order.orderProductItems[0];
        const sz = item.size.replace('.', '_');
        const style_code = item.styleCode
          .replace('-', '')
          .replace(' ', '')
          .toUpperCase()
          .trim();
        const id = style_code + '-' + sz;// + '-' + g;
        const price = parseFloat(order.orderTotal);
        const pixel_json = {
          content_name: item.name,
          content_category: item.brand[0],
          currency: 'USD',
          content_type: 'product',
          contents: [{
            id: id,
            quantity: 1,
            item_price: price
          }],
          content_ids: [id],
          value: price,
          num_items: 1
        }
        ReactPixel.track('Purchase', pixel_json);
        const ga_adwords = props.influencer.analytics.adwords ? props.influencer.analytics.adwords : '';
        if (ga_adwords) {
          const ga_adwords_conversion = props.influencer.analytics.adwordsconversion ? props.influencer.analytics.adwordsconversion : '66DaCLH_5n4Q7_qMywM';
          window.gtag('config', ga_adwords);
          window.gtag('event', 'conversion', {
            'send_to': ga_adwords + '/' + ga_adwords_conversion,
            'value': order.orderTotal,
            'currency': 'USD',
            'transaction_id': order.orderId
          });
          if (order.seller_estimate) {
            var google_opt = document.createElement("script");
            google_opt.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn';
            google_opt.async = true;
            google_opt.defer = true;
            document.getElementsByTagName('body')[0].appendChild(google_opt);
            const surveyoptin = {
              "merchant_id": 120810012,
              "order_id": JSON.stringify(order.orderId),
              "email": order.shippingAddress.emailAddress,
              "delivery_country": order.shippingAddress.countryIsoCode2,
              "estimated_delivery_date": order.seller_estimate,
            };
            window.renderOptIn = function () {
              window.gapi.load("surveyoptin", function () {
                window.gapi.surveyoptin.render(surveyoptin);
              });
            };
          }
        }
        window.gtag('event', 'purchase', {
          'event_label': 'Order #' + order.orderId,
          "transaction_id": order.orderId,
          "affiliation": props.influencer.subdomain,
          "value": order.orderTotal,
          "currency": "USD",
          "tax": order.orderTax,
          "shipping": order.orderShipping,
          "items": [
            {
              "id": id,
              "name": item.name,
              "sku": item.styleCode,
              "brand": item.brand[0],
              "category": item.brand[0],
              "list_position": 1,
              "quantity": 1,
              "price": price
            }
          ]
        });
        window.uetq = window.uetq || [];
        window.uetq.push('event', '', { 'revenue_value': order.orderTotal, 'currency': 'USD' });


        if(window.ire){
          window.ire('trackConversion', 42986, {
            orderId: order.orderId,
            customerId: props.user.id,
            customerEmail: props.user.customerSHA1, //SHA1(props.user.email),
            customerStatus: "New",
            currencyCode: "USD",
            orderPromoCode: "",
            orderDiscount: 0.00,
            items: [
                {
                  subTotal: price,
                  category: item.brand[0],
                  sku: item.styleCode,
                  quantity: 1,
                  name: item.name
                }
              ]
            },
            {
              verifySiteDefinitionMatch:true
            }
          );
        }
        return {
          order: order,
          is_loaded: 1
        }
      }
      //  else {
      //   props.history.push('/orders');
      // }
    }
    return null;
  }
  /*componentWillReceiveProps(nextProps) {
    if (this.props.loading != nextProps.loading && !nextProps.loading) {
      if (nextProps.order && nextProps.order.orderId) {
        this.props.checkout_complate(this.state.orderId, this.props.influencer, this.props.token);
        const order = nextProps.order;
        const item = order.orderProductItems[0];
        const sz = item.size.replace('.', '_');
        const style_code = item.styleCode
          .replace('-', '')
          .replace(' ', '')
          .toUpperCase()
          .trim();
        const id = style_code + '-' + sz;// + '-' + g;
        const price = parseFloat(order.orderTotal);
        const pixel_json = {
          content_name: item.name,
          content_category: item.brand[0],
          currency: 'USD',
          content_type: 'product',
          contents: [{
            id: id,
            quantity: 1,
            item_price: price
          }],
          content_ids: [id],
          value: price,
          num_items: 1
        }
        ReactPixel.track('Purchase', pixel_json);
        const ga_adwords = this.props.influencer.analytics.adwords ? this.props.influencer.analytics.adwords : '';
        if (ga_adwords) {
          const ga_adwords_conversion = this.props.influencer.analytics.adwordsconversion ? this.props.influencer.analytics.adwordsconversion : '66DaCLH_5n4Q7_qMywM';
          window.gtag('config', ga_adwords);
          window.gtag('event', 'conversion', {
            'send_to': ga_adwords + '/' + ga_adwords_conversion,
            'value': order.orderTotal,
            'currency': 'USD',
            'transaction_id': order.orderId
          });
          if (order.seller_estimate) {
            var google_opt = document.createElement("script");
            google_opt.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn';
            google_opt.async = true;
            google_opt.defer = true;
            document.getElementsByTagName('body')[0].appendChild(google_opt);
            const surveyoptin = {
              "merchant_id": 120810012,
              "order_id": JSON.stringify(order.orderId),
              "email": order.shippingAddress.emailAddress,
              "delivery_country": order.shippingAddress.countryIsoCode2,
              "estimated_delivery_date": order.seller_estimate,
            };
            window.renderOptIn = function () {
              window.gapi.load("surveyoptin", function () {
                window.gapi.surveyoptin.render(surveyoptin);
              });
            };
          }
        }
        window.gtag('event', 'purchase', {
          'event_label': 'Order #' + order.orderId,
          "transaction_id": order.orderId,
          "affiliation": this.props.influencer.subdomain,
          "value": order.orderTotal,
          "currency": "USD",
          "tax": order.orderTax,
          "shipping": order.orderShipping,
          "items": [
            {
              "id": id,
              "name": item.name,
              "sku": item.styleCode,
              "brand": item.brand[0],
              "category": item.brand[0],
              "list_position": 1,
              "quantity": 1,
              "price": price
            }
          ]
        });
        window.uetq = window.uetq || [];
        window.uetq.push('event', '', { 'revenue_value': order.orderTotal, 'currency': 'USD' });
        this.setState({ is_loaded: 1 });
      } else {
        this.props.history.push('/orders');
      }
    }
  }*/
  render() {
    const order_link = '/order/' + this.state.orderId;
    const show_order = this.state.is_loaded == 1 ? <div><h2>Thanks for your order!</h2><p>Your Order  Number: <Link to={order_link}><b><u>{this.state.orderId}</u></b></Link></p><p>Your order has been placed. You'll receive a confirmation email shortly.</p></div> : '';
    return (
      <div id="checkout-wrapper">
        <PageHeader>{this.state.pageTitle}</PageHeader>
        <Alerts alerts={this.props.alerts} />
        {show_order}
      </div>
    );
  }
}

Complete.contextTypes = {
  router: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    influencer: state.influencer,
    alerts: state.alerts,
    loading: state.settings.loading,
    order: state.order.item,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkout_complate: (orderId, influencer, token) => {
      dispatch(checkout_complate(orderId, influencer, token));
    },
    viewOrder: (orderId, influencer, token, is_complate) => {
      dispatch(view(orderId, influencer, token, is_complate));
    },
    emptyToCart: () => {
      dispatch(emptyToCart());
    },
    setMetatags: (metatags) => {
      dispatch(setMetatags(metatags));
    },
    clearAlerts: () => {
      dispatch({ type: 'CLEAR_ALERTS' });
    },
    clearOrder: () => {
      dispatch({ type: 'CLEAR_ORDER' });
    },
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Complete));
